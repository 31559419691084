@charset "UTF-8";
/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */
:root {
  --bs-blue: #2689E2;
  --bs-indigo: #6610F2;
  --bs-purple: #673AB7;
  --bs-pink: #E83E8C;
  --bs-red: #F44336;
  --bs-orange: #FD7E14;
  --bs-yellow: #FFC107;
  --bs-green: #00C853;
  --bs-teal: #20C997;
  --bs-cyan: #3EC9D6;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #2689E2;
  --bs-secondary: #6610F2;
  --bs-success: #00C853;
  --bs-info: #3EC9D6;
  --bs-warning: #FFC107;
  --bs-danger: #F44336;
  --bs-light: #f8f9fa;
  --bs-dark: #111936;
  --bs-primary-rgb: 38, 137, 226;
  --bs-secondary-rgb: 102, 16, 242;
  --bs-success-rgb: 0, 200, 83;
  --bs-info-rgb: 62, 201, 214;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 244, 67, 54;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 17, 25, 54;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 236, 239, 241;
  --bs-font-sans-serif: "Roboto", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #eceff1;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #eeeeee;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 8px;
  --bs-border-radius-sm: 4px;
  --bs-border-radius-lg: 10px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-heading-color: #212529;
  --bs-link-color: #2689E2;
  --bs-link-hover-color: #2689E2;
  --bs-code-color: #E83E8C;
  --bs-highlight-bg: #fcf8e3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.13;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.3375rem + 1.05vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.125rem;
  }
}

h2, .h2 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}

h3, .h3 {
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 80%;
}

mark, .mark {
  padding: 0.2em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 87.5%;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 4px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 80%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #eceff1;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: #212529;
  --bs-table-bg: transparent;
  --bs-table-border-color: #eeeeee;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(38, 137, 226, 0.02);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.9rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.3rem 0.3rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #212529;
  --bs-table-bg: #d4e7f9;
  --bs-table-border-color: #c2d4e4;
  --bs-table-striped-bg: #cbddef;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #c2d4e4;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #d0e3f5;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #212529;
  --bs-table-bg: #e0cffc;
  --bs-table-border-color: #cdbee7;
  --bs-table-striped-bg: #d6c7f1;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #cdbee7;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #dcccf8;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #212529;
  --bs-table-bg: #ccf4dd;
  --bs-table-border-color: #bbdfcb;
  --bs-table-striped-bg: #c3ead4;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #bbdfcb;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #c9f0d9;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #212529;
  --bs-table-bg: #d8f4f7;
  --bs-table-border-color: #c6dfe2;
  --bs-table-striped-bg: #cfeaed;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #c6dfe2;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #d4f0f3;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #212529;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e9debd;
  --bs-table-striped-bg: #f4e9c5;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e9debd;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #fbefca;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #212529;
  --bs-table-bg: #fdd9d7;
  --bs-table-border-color: #e7c7c6;
  --bs-table-striped-bg: #f2d0ce;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e7c7c6;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #f9d5d4;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #212529;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #e3e4e5;
  --bs-table-striped-bg: #edeef0;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e3e4e5;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #f4f5f6;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #111936;
  --bs-table-border-color: #29304a;
  --bs-table-striped-bg: #1d2540;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #29304a;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #161e3a;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  color: #212529;
}

.col-form-label {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: #212529;
}

.col-form-label-lg {
  padding-top: calc(0.775rem + 1px);
  padding-bottom: calc(0.775rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.65rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #2689E2;
  outline: 0;
  box-shadow: 0 0 0 1px #2689E2;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.65rem 0.75rem;
  margin: -0.65rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #eceff1;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e0e3e5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.65rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.7rem;
  font-size: 0.765625rem;
  border-radius: 6px;
}
.form-control-sm::file-selector-button {
  padding: 0.375rem 0.7rem;
  margin: -0.375rem -0.7rem;
  margin-inline-end: 0.7rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.55rem + 2px);
  padding: 0.775rem 0.85rem;
  font-size: 1.09375rem;
  border-radius: 6px;
}
.form-control-lg::file-selector-button {
  padding: 0.775rem 0.85rem;
  margin: -0.775rem -0.85rem;
  margin-inline-end: 0.85rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.3rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.55rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.65rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 6px;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 6px;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.65rem 2rem 0.65rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #2689E2;
  outline: 0;
  box-shadow: 0 0 0 1px #2689E2;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.7rem;
  font-size: 0.765625rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.775rem;
  padding-bottom: 0.775rem;
  padding-left: 0.85rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.75em;
}

.form-check-reverse {
  padding-right: 1.75em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.75em;
  margin-left: 0;
}

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.125em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #2689E2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 137, 226, 0.25);
}
.form-check-input:checked {
  background-color: #2689E2;
  border-color: #2689E2;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #2689E2;
  border-color: #2689E2;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232689E2'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #eceff1, 0 0 0 1px #2689E2;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #eceff1, 0 0 0 1px #2689E2;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2689E2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c3dff7;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #eceff1;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2689E2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c3dff7;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #eceff1;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.65rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #eceff1;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.775rem 0.85rem;
  font-size: 1.09375rem;
  border-radius: 6px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.375rem 0.7rem;
  font-size: 0.765625rem;
  border-radius: 6px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00C853;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 200, 83, 0.9);
  border-radius: 8px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00C853;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300C853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00C853;
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #00C853;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: calc(0.75em + 3.725rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300C853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.75rem;
  background-size: 16px 12px, calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #00C853;
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.3rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #00C853;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #00C853;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00C853;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F44336;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(244, 67, 54, 0.9);
  border-radius: 8px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F44336;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F44336' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F44336' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #F44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #F44336;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: calc(0.75em + 3.725rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F44336' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F44336' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-position: right 0.75rem center, center right 2.75rem;
  background-size: 16px 12px, calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #F44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.3rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #F44336;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #F44336;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F44336;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 4px;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check:focus + .btn, .btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2689E2;
  --bs-btn-border-color: #2689E2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2074c0;
  --bs-btn-hover-border-color: #1e6eb5;
  --bs-btn-focus-shadow-rgb: 71, 155, 230;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1e6eb5;
  --bs-btn-active-border-color: #1d67aa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2689E2;
  --bs-btn-disabled-border-color: #2689E2;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6610F2;
  --bs-btn-border-color: #6610F2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6610F2;
  --bs-btn-disabled-border-color: #6610F2;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #00C853;
  --bs-btn-border-color: #00C853;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #00aa47;
  --bs-btn-hover-border-color: #00a042;
  --bs-btn-focus-shadow-rgb: 38, 208, 109;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #00a042;
  --bs-btn-active-border-color: #00963e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #00C853;
  --bs-btn-disabled-border-color: #00C853;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3EC9D6;
  --bs-btn-border-color: #3EC9D6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #35abb6;
  --bs-btn-hover-border-color: #32a1ab;
  --bs-btn-focus-shadow-rgb: 91, 209, 220;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #32a1ab;
  --bs-btn-active-border-color: #2f97a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3EC9D6;
  --bs-btn-disabled-border-color: #3EC9D6;
}

.btn-warning {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #FFC107;
  --bs-btn-border-color: #FFC107;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d9a406;
  --bs-btn-hover-border-color: #cc9a06;
  --bs-btn-focus-shadow-rgb: 255, 202, 44;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc9a06;
  --bs-btn-active-border-color: #bf9105;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #FFC107;
  --bs-btn-disabled-border-color: #FFC107;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #F44336;
  --bs-btn-border-color: #F44336;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #cf392e;
  --bs-btn-hover-border-color: #c3362b;
  --bs-btn-focus-shadow-rgb: 246, 95, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c3362b;
  --bs-btn-active-border-color: #b73229;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #F44336;
  --bs-btn-disabled-border-color: #F44336;
}

.btn-light {
  --bs-btn-color: #212529;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: #f9fafb;
  --bs-btn-hover-border-color: #f9fafb;
  --bs-btn-focus-shadow-rgb: 216, 217, 219;
  --bs-btn-active-color: #212529;
  --bs-btn-active-bg: #f9fafb;
  --bs-btn-active-border-color: #f9fafb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #111936;
  --bs-btn-border-color: #111936;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0e152e;
  --bs-btn-hover-border-color: #0e142b;
  --bs-btn-focus-shadow-rgb: 53, 60, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0e142b;
  --bs-btn-active-border-color: #0d1329;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #111936;
  --bs-btn-disabled-border-color: #111936;
}

.btn-outline-primary {
  --bs-btn-color: #2689E2;
  --bs-btn-border-color: #2689E2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2689E2;
  --bs-btn-hover-border-color: #2689E2;
  --bs-btn-focus-shadow-rgb: 38, 137, 226;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2689E2;
  --bs-btn-active-border-color: #2689E2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2689E2;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6610F2;
  --bs-btn-border-color: #6610F2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6610F2;
  --bs-btn-hover-border-color: #6610F2;
  --bs-btn-focus-shadow-rgb: 102, 16, 242;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6610F2;
  --bs-btn-active-border-color: #6610F2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6610F2;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #00C853;
  --bs-btn-border-color: #00C853;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #00C853;
  --bs-btn-hover-border-color: #00C853;
  --bs-btn-focus-shadow-rgb: 0, 200, 83;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #00C853;
  --bs-btn-active-border-color: #00C853;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00C853;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #3EC9D6;
  --bs-btn-border-color: #3EC9D6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3EC9D6;
  --bs-btn-hover-border-color: #3EC9D6;
  --bs-btn-focus-shadow-rgb: 62, 201, 214;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3EC9D6;
  --bs-btn-active-border-color: #3EC9D6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3EC9D6;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFC107;
  --bs-btn-border-color: #FFC107;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #FFC107;
  --bs-btn-hover-border-color: #FFC107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #FFC107;
  --bs-btn-active-border-color: #FFC107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFC107;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #F44336;
  --bs-btn-border-color: #F44336;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #F44336;
  --bs-btn-hover-border-color: #F44336;
  --bs-btn-focus-shadow-rgb: 244, 67, 54;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #F44336;
  --bs-btn-active-border-color: #F44336;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F44336;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #212529;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #111936;
  --bs-btn-border-color: #111936;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #111936;
  --bs-btn-hover-border-color: #111936;
  --bs-btn-focus-shadow-rgb: 17, 25, 54;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #111936;
  --bs-btn-active-border-color: #111936;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #111936;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #2689E2;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #2689E2;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus {
  text-decoration: underline;
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 1.3rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: 6px;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.765625rem;
  --bs-btn-border-radius: 2px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-border-radius: 8px;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: 8px;
  --bs-dropdown-divider-bg: #e9ecef;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #2689E2;
  --bs-dropdown-link-hover-bg: #d4e7f9;
  --bs-dropdown-link-active-color: #2689E2;
  --bs-dropdown-link-active-bg: #d4e7f9;
  --bs-dropdown-link-disabled-color: #6c757d;
  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: 0.65rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1026;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: #e9ecef;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #2689E2;
  --bs-dropdown-link-active-bg: #d4e7f9;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 4px;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.975rem;
  padding-left: 0.975rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: ;
  --bs-nav-link-hover-color: ;
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 8px;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #ffffff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #ffffff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 8px;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #2689E2;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: calc(1rem / 2);
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3359375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 4px;
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 25px;
  --bs-card-spacer-x: 25px;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0px;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 8px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(8px - 0px);
  --bs-card-cap-padding-y: 25px;
  --bs-card-cap-padding-x: 25px;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #ffffff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 8px;
  --bs-accordion-inner-border-radius: 7px;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23227bcb'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #2689E2;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(38, 137, 226, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #227bcb;
  --bs-accordion-active-bg: #e9f3fc;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 2;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%236c757d' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E")) /* rtl: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%236c757d' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E")) */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #2689E2;
  --bs-pagination-bg: #ffffff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 8px;
  --bs-pagination-hover-color: #2689E2;
  --bs-pagination-hover-bg: #eceff1;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: #2689E2;
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(38, 137, 226, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #2689E2;
  --bs-pagination-active-border-color: #2689E2;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #ffffff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: 10px;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.765625rem;
  --bs-pagination-border-radius: 4px;
}

.badge {
  --bs-badge-padding-x: 0.5em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 2px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius, 0);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 8px;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius, 0);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}

.alert-primary {
  --bs-alert-color: #175288;
  --bs-alert-bg: #d4e7f9;
  --bs-alert-border-color: #bedcf6;
}
.alert-primary .alert-link {
  color: #12426d;
}

.alert-secondary {
  --bs-alert-color: #3d0a91;
  --bs-alert-bg: #e0cffc;
  --bs-alert-border-color: #d1b7fb;
}
.alert-secondary .alert-link {
  color: #310874;
}

.alert-success {
  --bs-alert-color: #007832;
  --bs-alert-bg: #ccf4dd;
  --bs-alert-border-color: #b3efcb;
}
.alert-success .alert-link {
  color: #006028;
}

.alert-info {
  --bs-alert-color: #257980;
  --bs-alert-bg: #d8f4f7;
  --bs-alert-border-color: #c5eff3;
}
.alert-info .alert-link {
  color: #1e6166;
}

.alert-warning {
  --bs-alert-color: #997404;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #7a5d03;
}

.alert-danger {
  --bs-alert-color: #922820;
  --bs-alert-bg: #fdd9d7;
  --bs-alert-border-color: #fcc7c3;
}
.alert-danger .alert-link {
  color: #75201a;
}

.alert-light {
  --bs-alert-color: #959596;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #777778;
}

.alert-dark {
  --bs-alert-color: #0a0f20;
  --bs-alert-bg: #cfd1d7;
  --bs-alert-border-color: #b8bac3;
}
.alert-dark .alert-link {
  color: #080c1a;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: #eceff1;
  --bs-progress-border-radius: 8px;
  --bs-progress-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #2689E2;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: ;
  --bs-list-group-bg: #ffffff;
  --bs-list-group-border-color: #eeeeee;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 8px;
  --bs-list-group-item-padding-x: 25px;
  --bs-list-group-item-padding-y: 16.6666666667px;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #ffffff;
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #2689E2;
  --bs-list-group-active-border-color: #2689E2;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #175288;
  background-color: #d4e7f9;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #175288;
  background-color: #bfd0e0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #175288;
  border-color: #175288;
}

.list-group-item-secondary {
  color: #3d0a91;
  background-color: #e0cffc;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3d0a91;
  background-color: #cabae3;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #3d0a91;
  border-color: #3d0a91;
}

.list-group-item-success {
  color: #007832;
  background-color: #ccf4dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #007832;
  background-color: #b8dcc7;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #007832;
  border-color: #007832;
}

.list-group-item-info {
  color: #257980;
  background-color: #d8f4f7;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #257980;
  background-color: #c2dcde;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #257980;
  border-color: #257980;
}

.list-group-item-warning {
  color: #997404;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #997404;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #997404;
  border-color: #997404;
}

.list-group-item-danger {
  color: #922820;
  background-color: #fdd9d7;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #922820;
  background-color: #e4c3c2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #922820;
  border-color: #922820;
}

.list-group-item-light {
  color: #959596;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #959596;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #959596;
  border-color: #959596;
}

.list-group-item-dark {
  color: #0a0f20;
  background-color: #cfd1d7;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0a0f20;
  background-color: #babcc2;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #0a0f20;
  border-color: #0a0f20;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 8px;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 137, 226, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.25rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 1090;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.25rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 10px;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  --bs-modal-inner-border-radius: 9px;
  --bs-modal-header-padding-x: 1.5625rem;
  --bs-modal-header-padding-y: 1.25rem;
  --bs-modal-header-padding: 1.25rem 1.5625rem;
  --bs-modal-header-border-color: #eeeeee;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #eeeeee;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.765625rem;
  --bs-tooltip-color: #ffffff;
  --bs-tooltip-bg: #000000;
  --bs-tooltip-border-radius: 8px;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius, 0);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.765625rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgba(0, 0, 0, 0.2);
  --bs-popover-border-radius: 10px;
  --bs-popover-inner-border-radius: 9px;
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 0.75rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #212529;
  --bs-popover-header-bg: #f7f7f7;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -0.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-width: 360px;
  --bs-offcanvas-height: 33vh;
  --bs-offcanvas-padding-x: 1.25rem;
  --bs-offcanvas-padding-y: 1.25rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #ffffff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(38, 137, 226, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(102, 16, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(0, 200, 83, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(62, 201, 214, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #ffffff !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(244, 67, 54, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #212529 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(17, 25, 54, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #2689E2 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #1e6eb5 !important;
}

.link-secondary {
  color: #6610F2 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #520dc2 !important;
}

.link-success {
  color: #00C853 !important;
}
.link-success:hover, .link-success:focus {
  color: #00a042 !important;
}

.link-info {
  color: #3EC9D6 !important;
}
.link-info:hover, .link-info:focus {
  color: #32a1ab !important;
}

.link-warning {
  color: #FFC107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #cc9a06 !important;
}

.link-danger {
  color: #F44336 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #c3362b !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #111936 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #0e142b !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.13;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-0 {
  --bs-border-width: 0;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3375rem + 1.05vw) !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
  font-size: 1.25rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: 0.875rem !important;
}

.fs-6 {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.125rem !important;
  }

  .fs-2 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px;
}
.p-t-0 {
  padding-top: 0px;
}
.p-b-0 {
  padding-bottom: 0px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-r-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}
.m-t-0 {
  margin-top: 0px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-r-0 {
  margin-right: 0px;
}

.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-r-50 {
  margin-right: 50px;
}

/*====== Padding , Margin css ends ======*/
/*====== Font-size css starts ======*/
.f-6 {
  font-size: 6px;
}

.f-8 {
  font-size: 8px;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
.wid-10 {
  width: 10px;
}

.hei-10 {
  height: 10px;
}

.wid-15 {
  width: 15px;
}

.hei-15 {
  height: 15px;
}

.wid-20 {
  width: 20px;
}

.hei-20 {
  height: 20px;
}

.wid-25 {
  width: 25px;
}

.hei-25 {
  height: 25px;
}

.wid-30 {
  width: 30px;
}

.hei-30 {
  height: 30px;
}

.wid-35 {
  width: 35px;
}

.hei-35 {
  height: 35px;
}

.wid-40 {
  width: 40px;
}

.hei-40 {
  height: 40px;
}

.wid-45 {
  width: 45px;
}

.hei-45 {
  height: 45px;
}

.wid-50 {
  width: 50px;
}

.hei-50 {
  height: 50px;
}

.wid-55 {
  width: 55px;
}

.hei-55 {
  height: 55px;
}

.wid-60 {
  width: 60px;
}

.hei-60 {
  height: 60px;
}

.wid-65 {
  width: 65px;
}

.hei-65 {
  height: 65px;
}

.wid-70 {
  width: 70px;
}

.hei-70 {
  height: 70px;
}

.wid-75 {
  width: 75px;
}

.hei-75 {
  height: 75px;
}

.wid-80 {
  width: 80px;
}

.hei-80 {
  height: 80px;
}

.wid-85 {
  width: 85px;
}

.hei-85 {
  height: 85px;
}

.wid-90 {
  width: 90px;
}

.hei-90 {
  height: 90px;
}

.wid-95 {
  width: 95px;
}

.hei-95 {
  height: 95px;
}

.wid-100 {
  width: 100px;
}

.hei-100 {
  height: 100px;
}

.wid-105 {
  width: 105px;
}

.hei-105 {
  height: 105px;
}

.wid-110 {
  width: 110px;
}

.hei-110 {
  height: 110px;
}

.wid-115 {
  width: 115px;
}

.hei-115 {
  height: 115px;
}

.wid-120 {
  width: 120px;
}

.hei-120 {
  height: 120px;
}

.wid-125 {
  width: 125px;
}

.hei-125 {
  height: 125px;
}

.wid-130 {
  width: 130px;
}

.hei-130 {
  height: 130px;
}

.wid-135 {
  width: 135px;
}

.hei-135 {
  height: 135px;
}

.wid-140 {
  width: 140px;
}

.hei-140 {
  height: 140px;
}

.wid-145 {
  width: 145px;
}

.hei-145 {
  height: 145px;
}

.wid-150 {
  width: 150px;
}

.hei-150 {
  height: 150px;
}

/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
.b-wid-1 {
  border-width: 1px;
}

.b-wid-2 {
  border-width: 2px;
}

.b-wid-3 {
  border-width: 3px;
}

.b-wid-4 {
  border-width: 4px;
}

.b-wid-5 {
  border-width: 5px;
}

.b-wid-6 {
  border-width: 6px;
}

.b-wid-7 {
  border-width: 7px;
}

.b-wid-8 {
  border-width: 8px;
}

/*====== border-width css ends ======*/
/*====== background starts ======*/
.text-header {
  color: var(--bs-heading-color);
}

.bg-body {
  background: var(--bs-body-bg);
}

.bg-light-primary {
  background: #d4e7f9;
  color: #2689E2;
}

.icon-svg-primary {
  fill: #d4e7f9;
  stroke: #2689E2;
}

.material-icons-two-tone.text-primary {
  background-color: #2689E2;
}

.text-hover-primary:hover {
  color: #2689E2 !important;
}

.bg-light-secondary {
  background: #e0cffc;
  color: #6610F2;
}

.icon-svg-secondary {
  fill: #e0cffc;
  stroke: #6610F2;
}

.material-icons-two-tone.text-secondary {
  background-color: #6610F2;
}

.text-hover-secondary:hover {
  color: #6610F2 !important;
}

.bg-light-success {
  background: #ccf4dd;
  color: #00C853;
}

.icon-svg-success {
  fill: #ccf4dd;
  stroke: #00C853;
}

.material-icons-two-tone.text-success {
  background-color: #00C853;
}

.text-hover-success:hover {
  color: #00C853 !important;
}

.bg-light-info {
  background: #d8f4f7;
  color: #3EC9D6;
}

.icon-svg-info {
  fill: #d8f4f7;
  stroke: #3EC9D6;
}

.material-icons-two-tone.text-info {
  background-color: #3EC9D6;
}

.text-hover-info:hover {
  color: #3EC9D6 !important;
}

.bg-light-warning {
  background: #fff3cd;
  color: #FFC107;
}

.icon-svg-warning {
  fill: #fff3cd;
  stroke: #FFC107;
}

.material-icons-two-tone.text-warning {
  background-color: #FFC107;
}

.text-hover-warning:hover {
  color: #FFC107 !important;
}

.bg-light-danger {
  background: #fdd9d7;
  color: #F44336;
}

.icon-svg-danger {
  fill: #fdd9d7;
  stroke: #F44336;
}

.material-icons-two-tone.text-danger {
  background-color: #F44336;
}

.text-hover-danger:hover {
  color: #F44336 !important;
}

.bg-light-light {
  background: #fefefe;
  color: #f8f9fa;
}

.icon-svg-light {
  fill: #fefefe;
  stroke: #f8f9fa;
}

.material-icons-two-tone.text-light {
  background-color: #f8f9fa;
}

.text-hover-light:hover {
  color: #f8f9fa !important;
}

.bg-light-dark {
  background: #cfd1d7;
  color: #111936;
}

.icon-svg-dark {
  fill: #cfd1d7;
  stroke: #111936;
}

.material-icons-two-tone.text-dark {
  background-color: #111936;
}

.text-hover-dark:hover {
  color: #111936 !important;
}

/*====== background ends ======*/
/*====== border color css starts ======*/
.b-primary {
  border: 1px solid #2689E2;
}

.border-bottom-primary td {
  border-bottom: 1px solid #2689E2;
}

.border-bottom-primary th {
  border-bottom: 1px solid #2689E2 !important;
}

.fill-primary {
  fill: #2689E2;
}

.b-secondary {
  border: 1px solid #6610F2;
}

.border-bottom-secondary td {
  border-bottom: 1px solid #6610F2;
}

.border-bottom-secondary th {
  border-bottom: 1px solid #6610F2 !important;
}

.fill-secondary {
  fill: #6610F2;
}

.b-success {
  border: 1px solid #00C853;
}

.border-bottom-success td {
  border-bottom: 1px solid #00C853;
}

.border-bottom-success th {
  border-bottom: 1px solid #00C853 !important;
}

.fill-success {
  fill: #00C853;
}

.b-info {
  border: 1px solid #3EC9D6;
}

.border-bottom-info td {
  border-bottom: 1px solid #3EC9D6;
}

.border-bottom-info th {
  border-bottom: 1px solid #3EC9D6 !important;
}

.fill-info {
  fill: #3EC9D6;
}

.b-warning {
  border: 1px solid #FFC107;
}

.border-bottom-warning td {
  border-bottom: 1px solid #FFC107;
}

.border-bottom-warning th {
  border-bottom: 1px solid #FFC107 !important;
}

.fill-warning {
  fill: #FFC107;
}

.b-danger {
  border: 1px solid #F44336;
}

.border-bottom-danger td {
  border-bottom: 1px solid #F44336;
}

.border-bottom-danger th {
  border-bottom: 1px solid #F44336 !important;
}

.fill-danger {
  fill: #F44336;
}

.b-light {
  border: 1px solid #f8f9fa;
}

.border-bottom-light td {
  border-bottom: 1px solid #f8f9fa;
}

.border-bottom-light th {
  border-bottom: 1px solid #f8f9fa !important;
}

.fill-light {
  fill: #f8f9fa;
}

.b-dark {
  border: 1px solid #111936;
}

.border-bottom-dark td {
  border-bottom: 1px solid #111936;
}

.border-bottom-dark th {
  border-bottom: 1px solid #111936 !important;
}

.fill-dark {
  fill: #111936;
}

/*====== border color css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-light-facebook {
  background: #d9e1f0;
  color: #4267b2;
}

.bg-facebook {
  background: #4267b2 !important;
}

.text-facebook {
  color: #4267b2;
}

.bg-light-twitter {
  background: #d9f2fe;
  color: #42c0fb;
}

.bg-twitter {
  background: #42c0fb !important;
}

.text-twitter {
  color: #42c0fb;
}

.bg-light-dribbble {
  background: #fbdbe7;
  color: #ec4a89;
}

.bg-dribbble {
  background: #ec4a89 !important;
}

.text-dribbble {
  color: #ec4a89;
}

.bg-light-pinterest {
  background: #f2d3d6;
  color: #bf2131;
}

.bg-pinterest {
  background: #bf2131 !important;
}

.text-pinterest {
  color: #bf2131;
}

.bg-light-youtube {
  background: #f9d4d2;
  color: #e0291d;
}

.bg-youtube {
  background: #e0291d !important;
}

.text-youtube {
  color: #e0291d;
}

.bg-light-googleplus {
  background: #f4d8d5;
  color: #c73e2e;
}

.bg-googleplus {
  background: #c73e2e !important;
}

.text-googleplus {
  color: #c73e2e;
}

.bg-light-instagram {
  background: #eee5e0;
  color: #aa7c62;
}

.bg-instagram {
  background: #aa7c62 !important;
}

.text-instagram {
  color: #aa7c62;
}

.bg-light-viber {
  background: #e5dceb;
  color: #7b519d;
}

.bg-viber {
  background: #7b519d !important;
}

.text-viber {
  color: #7b519d;
}

.bg-light-behance {
  background: #ccddff;
  color: #0057ff;
}

.bg-behance {
  background: #0057ff !important;
}

.text-behance {
  color: #0057ff;
}

.bg-light-dropbox {
  background: #d6e6ff;
  color: #3380ff;
}

.bg-dropbox {
  background: #3380ff !important;
}

.text-dropbox {
  color: #3380ff;
}

.bg-light-linkedin {
  background: #cce4f0;
  color: #0077b5;
}

.bg-linkedin {
  background: #0077b5 !important;
}

.text-linkedin {
  color: #0077b5;
}

.bg-light-amazon {
  background: #cccccc;
  color: #000000;
}

.bg-amazon {
  background: #000000 !important;
}

.text-amazon {
  color: #000000;
}

/*====== text-color css ends ======*/
.font-hairline {
  font-weight: 100 !important;
}

.font-thin {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-black {
  font-weight: 900 !important;
}

.text-xs {
  font-size: 0.625rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-md {
  font-size: 0.8125rem !important;
}

.text-base {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.125rem !important;
}

.text-2xl {
  font-size: 1.25rem !important;
}

.text-3xl {
  font-size: 1.5rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.25rem !important;
}

.text-6xl {
  font-size: 2.5rem !important;
}

.text-7xl {
  font-size: 3rem !important;
}

.text-8xl {
  font-size: 4rem !important;
}

.text-9xl {
  font-size: 6rem !important;
}

.text-10xl {
  font-size: 8rem !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

/*====== more bootstrap colors start ======*/
.bg-blue-100 {
  background: #BEDCF6;
}

.text-blue-100 {
  color: #BEDCF6;
}

.bg-blue-200 {
  background: #93C4F1;
}

.text-blue-200 {
  color: #93C4F1;
}

.bg-blue-300 {
  background: #67ACEB;
}

.text-blue-300 {
  color: #67ACEB;
}

.bg-blue-400 {
  background: #479BE6;
}

.text-blue-400 {
  color: #479BE6;
}

.bg-blue-500 {
  background: #2689E2;
}

.text-blue-500 {
  color: #2689E2;
}

.bg-blue-600 {
  background: #2281DF;
}

.text-blue-600 {
  color: #2281DF;
}

.bg-blue-700 {
  background: #1C76DA;
}

.text-blue-700 {
  color: #1C76DA;
}

.bg-blue-800 {
  background: #176CD6;
}

.text-blue-800 {
  color: #176CD6;
}

.bg-blue-900 {
  background: #0D59CF;
}

.text-blue-900 {
  color: #0D59CF;
}

.bg-indigo-100 {
  background: #D1B7FB;
}

.text-indigo-100 {
  color: #D1B7FB;
}

.bg-indigo-200 {
  background: #B388F9;
}

.text-indigo-200 {
  color: #B388F9;
}

.bg-indigo-300 {
  background: #9458F6;
}

.text-indigo-300 {
  color: #9458F6;
}

.bg-indigo-400 {
  background: #7D34F4;
}

.text-indigo-400 {
  color: #7D34F4;
}

.bg-indigo-500 {
  background: #6610F2;
}

.text-indigo-500 {
  color: #6610F2;
}

.bg-indigo-600 {
  background: #5E0EF0;
}

.text-indigo-600 {
  color: #5E0EF0;
}

.bg-indigo-700 {
  background: #530CEE;
}

.text-indigo-700 {
  color: #530CEE;
}

.bg-indigo-800 {
  background: #4909EC;
}

.text-indigo-800 {
  color: #4909EC;
}

.bg-indigo-900 {
  background: #3805E8;
}

.text-indigo-900 {
  color: #3805E8;
}

.bg-purple-100 {
  background: #D1C4E9;
}

.text-purple-100 {
  color: #D1C4E9;
}

.bg-purple-200 {
  background: #B39DDB;
}

.text-purple-200 {
  color: #B39DDB;
}

.bg-purple-300 {
  background: #9575CD;
}

.text-purple-300 {
  color: #9575CD;
}

.bg-purple-400 {
  background: #7E58C2;
}

.text-purple-400 {
  color: #7E58C2;
}

.bg-purple-500 {
  background: #673AB7;
}

.text-purple-500 {
  color: #673AB7;
}

.bg-purple-600 {
  background: #5F34B0;
}

.text-purple-600 {
  color: #5F34B0;
}

.bg-purple-700 {
  background: #542CA7;
}

.text-purple-700 {
  color: #542CA7;
}

.bg-purple-800 {
  background: #4A259F;
}

.text-purple-800 {
  color: #4A259F;
}

.bg-purple-900 {
  background: #391890;
}

.text-purple-900 {
  color: #391890;
}

.bg-pink-100 {
  background: #F8C5DD;
}

.text-pink-100 {
  color: #F8C5DD;
}

.bg-pink-200 {
  background: #F49FC6;
}

.text-pink-200 {
  color: #F49FC6;
}

.bg-pink-300 {
  background: #EF78AF;
}

.text-pink-300 {
  color: #EF78AF;
}

.bg-pink-400 {
  background: #EB5B9D;
}

.text-pink-400 {
  color: #EB5B9D;
}

.bg-pink-500 {
  background: #E83E8C;
}

.text-pink-500 {
  color: #E83E8C;
}

.bg-pink-600 {
  background: #E53884;
}

.text-pink-600 {
  color: #E53884;
}

.bg-pink-700 {
  background: #E23079;
}

.text-pink-700 {
  color: #E23079;
}

.bg-pink-800 {
  background: #DE286F;
}

.text-pink-800 {
  color: #DE286F;
}

.bg-pink-900 {
  background: #D81B5C;
}

.text-pink-900 {
  color: #D81B5C;
}

.bg-red-100 {
  background: #FCC7C3;
}

.text-red-100 {
  color: #FCC7C3;
}

.bg-red-200 {
  background: #FAA19B;
}

.text-red-200 {
  color: #FAA19B;
}

.bg-red-300 {
  background: #F77B72;
}

.text-red-300 {
  color: #F77B72;
}

.bg-red-400 {
  background: #F65F54;
}

.text-red-400 {
  color: #F65F54;
}

.bg-red-500 {
  background: #F44336;
}

.text-red-500 {
  color: #F44336;
}

.bg-red-600 {
  background: #F33D30;
}

.text-red-600 {
  color: #F33D30;
}

.bg-red-700 {
  background: #F13429;
}

.text-red-700 {
  color: #F13429;
}

.bg-red-800 {
  background: #EF2C22;
}

.text-red-800 {
  color: #EF2C22;
}

.bg-red-900 {
  background: #EC1E16;
}

.text-red-900 {
  color: #EC1E16;
}

.bg-orange-100 {
  background: #FED8B9;
}

.text-orange-100 {
  color: #FED8B9;
}

.bg-orange-200 {
  background: #FEBF8A;
}

.text-orange-200 {
  color: #FEBF8A;
}

.bg-orange-300 {
  background: #FEA55B;
}

.text-orange-300 {
  color: #FEA55B;
}

.bg-orange-400 {
  background: #FD9137;
}

.text-orange-400 {
  color: #FD9137;
}

.bg-orange-500 {
  background: #FD7E14;
}

.text-orange-500 {
  color: #FD7E14;
}

.bg-orange-600 {
  background: #FD7612;
}

.text-orange-600 {
  color: #FD7612;
}

.bg-orange-700 {
  background: #FC6B0E;
}

.text-orange-700 {
  color: #FC6B0E;
}

.bg-orange-800 {
  background: #FC610B;
}

.text-orange-800 {
  color: #FC610B;
}

.bg-orange-900 {
  background: #FC4E06;
}

.text-orange-900 {
  color: #FC4E06;
}

.bg-yellow-100 {
  background: #FFECB5;
}

.text-yellow-100 {
  color: #FFECB5;
}

.bg-yellow-200 {
  background: #FFE083;
}

.text-yellow-200 {
  color: #FFE083;
}

.bg-yellow-300 {
  background: #FFD451;
}

.text-yellow-300 {
  color: #FFD451;
}

.bg-yellow-400 {
  background: #FFCA2C;
}

.text-yellow-400 {
  color: #FFCA2C;
}

.bg-yellow-500 {
  background: #FFC107;
}

.text-yellow-500 {
  color: #FFC107;
}

.bg-yellow-600 {
  background: #FFBB06;
}

.text-yellow-600 {
  color: #FFBB06;
}

.bg-yellow-700 {
  background: #FFB305;
}

.text-yellow-700 {
  color: #FFB305;
}

.bg-yellow-800 {
  background: #FFAB04;
}

.text-yellow-800 {
  color: #FFAB04;
}

.bg-yellow-900 {
  background: #FF9E02;
}

.text-yellow-900 {
  color: #FF9E02;
}

.bg-green-100 {
  background: #B3EFCB;
}

.text-green-100 {
  color: #B3EFCB;
}

.bg-green-200 {
  background: #80E4A9;
}

.text-green-200 {
  color: #80E4A9;
}

.bg-green-300 {
  background: #4DD987;
}

.text-green-300 {
  color: #4DD987;
}

.bg-green-400 {
  background: #26D06D;
}

.text-green-400 {
  color: #26D06D;
}

.bg-green-500 {
  background: #00C853;
}

.text-green-500 {
  color: #00C853;
}

.bg-green-600 {
  background: #00C24C;
}

.text-green-600 {
  color: #00C24C;
}

.bg-green-700 {
  background: #00BB42;
}

.text-green-700 {
  color: #00BB42;
}

.bg-green-800 {
  background: #00B439;
}

.text-green-800 {
  color: #00B439;
}

.bg-green-900 {
  background: #00A729;
}

.text-green-900 {
  color: #00A729;
}

.bg-teal-100 {
  background: #BCEFE0;
}

.text-teal-100 {
  color: #BCEFE0;
}

.bg-teal-200 {
  background: #90E4CB;
}

.text-teal-200 {
  color: #90E4CB;
}

.bg-teal-300 {
  background: #63D9B6;
}

.text-teal-300 {
  color: #63D9B6;
}

.bg-teal-400 {
  background: #41D1A7;
}

.text-teal-400 {
  color: #41D1A7;
}

.bg-teal-500 {
  background: #20C997;
}

.text-teal-500 {
  color: #20C997;
}

.bg-teal-600 {
  background: #1CC38F;
}

.text-teal-600 {
  color: #1CC38F;
}

.bg-teal-700 {
  background: #18BC84;
}

.text-teal-700 {
  color: #18BC84;
}

.bg-teal-800 {
  background: #13B57A;
}

.text-teal-800 {
  color: #13B57A;
}

.bg-teal-900 {
  background: #0BA969;
}

.text-teal-900 {
  color: #0BA969;
}

.bg-cyan-100 {
  background: #C5EFF3;
}

.text-cyan-100 {
  color: #C5EFF3;
}

.bg-cyan-200 {
  background: #9FE4EB;
}

.text-cyan-200 {
  color: #9FE4EB;
}

.bg-cyan-300 {
  background: #78D9E2;
}

.text-cyan-300 {
  color: #78D9E2;
}

.bg-cyan-400 {
  background: #5BD1DC;
}

.text-cyan-400 {
  color: #5BD1DC;
}

.bg-cyan-500 {
  background: #3EC9D6;
}

.text-cyan-500 {
  color: #3EC9D6;
}

.bg-cyan-600 {
  background: #38C3D1;
}

.text-cyan-600 {
  color: #38C3D1;
}

.bg-cyan-700 {
  background: #30BCCC;
}

.text-cyan-700 {
  color: #30BCCC;
}

.bg-cyan-800 {
  background: #28B5C6;
}

.text-cyan-800 {
  color: #28B5C6;
}

.bg-cyan-900 {
  background: #1BA9BC;
}

.text-cyan-900 {
  color: #1BA9BC;
}

.bg-gray-100 {
  background: #f8f9fa;
}

.text-gray-100 {
  color: #f8f9fa;
}

.bg-gray-200 {
  background: #e9ecef;
}

.text-gray-200 {
  color: #e9ecef;
}

.bg-gray-300 {
  background: #dee2e6;
}

.text-gray-300 {
  color: #dee2e6;
}

.bg-gray-400 {
  background: #ced4da;
}

.text-gray-400 {
  color: #ced4da;
}

.bg-gray-500 {
  background: #adb5bd;
}

.text-gray-500 {
  color: #adb5bd;
}

.bg-gray-600 {
  background: #6c757d;
}

.text-gray-600 {
  color: #6c757d;
}

.bg-gray-700 {
  background: #495057;
}

.text-gray-700 {
  color: #495057;
}

.bg-gray-800 {
  background: #343a40;
}

.text-gray-800 {
  color: #343a40;
}

.bg-gray-900 {
  background: #212529;
}

.text-gray-900 {
  color: #212529;
}

/*====== more bootstrap colors end ======*/
/**  =====================
      Custom css start
==========================  **/
*:focus {
  outline: none;
}

.accordion {
  --bs-accordion-color: #212529;
}

a:hover {
  outline: none;
  text-decoration: none;
}
a:not([href]) {
  color: inherit;
}

p {
  font-size: 14px;
}

b,
strong {
  font-weight: 600;
}

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}
.ie-warning p {
  font-size: 17px;
}
.ie-warning h1, .ie-warning .h1 {
  color: #fff;
}
.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}
.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}
.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}
.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}
.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}
.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

.btn-page .btn {
  margin-right: 5px;
  margin-bottom: 5px;
}
.btn-page .btn-group .btn {
  margin-right: 0;
  margin-bottom: 0;
}

.material-icons-two-tone {
  background-color: #212529;
  -webkit-text-fill-color: transparent;
  vertical-align: text-bottom;
  -webkit-background-clip: text;
}
.material-icons-two-tone.text-white {
  background-color: #fff;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: #eceff1;
  padding: 15px;
  margin-bottom: 15px;
}
.bd-example-row .row > [class^=col-],
.bd-example-row .row > .col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}
.bd-example-row .row + .row {
  margin-top: 1rem;
}
.bd-example-row .flex-items-bottom,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-top {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0;
}
@media only screen and (max-height: 575px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

/* Example modals */
.modal {
  z-index: 1072;
}
.modal .popover,
.modal .tooltip {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #eceff1;
}
.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}
.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}
.bd-example-modal .offcanvas {
  position: static;
  display: block;
  height: 200px;
  visibility: visible;
  transform: translate(0);
}

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

/* Code snippets */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media only screen and (max-height: 575px) {
  .highlight {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;
}
@media only screen and (max-height: 575px) {
  .bd-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}
.highlight pre code {
  font-size: inherit;
  color: #333;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: transparent;
}

/* ================================    Blockquote Start  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
pre[class*=language-]:before,
pre[class*=language-]:after {
  display: none;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  display: flex;
  margin: 0;
}

pre[class*=language-] > code {
  width: 100%;
}

.media {
  display: flex;
}
.media .media-body {
  flex-grow: 1;
}

.blockquote {
  padding: 0.5rem 1rem;
}

/* ================================    Blockquote End  ===================== */
.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  transition: all 0.5s ease;
}

.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}

.color-card .card-body {
  margin: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  background: rgba(107, 117, 125, 0.08);
  border-radius: 8px;
}

.color-block {
  border-radius: 8px;
  margin: 4px 0;
}
.color-block.bg-blue-100 {
  color: #212529;
}
.color-block.text-blue-100 {
  background-color: #212529;
}
.color-block.bg-blue-200 {
  color: #ffffff;
}
.color-block.text-blue-200 {
  background-color: #ffffff;
}
.color-block.bg-blue-300 {
  color: #ffffff;
}
.color-block.text-blue-300 {
  background-color: #ffffff;
}
.color-block.bg-blue-400 {
  color: #ffffff;
}
.color-block.text-blue-400 {
  background-color: #ffffff;
}
.color-block.bg-blue-500 {
  color: #ffffff;
}
.color-block.text-blue-500 {
  background-color: #ffffff;
}
.color-block.bg-blue-600 {
  color: #ffffff;
}
.color-block.text-blue-600 {
  background-color: #ffffff;
}
.color-block.bg-blue-700 {
  color: #ffffff;
}
.color-block.text-blue-700 {
  background-color: #ffffff;
}
.color-block.bg-blue-800 {
  color: #ffffff;
}
.color-block.text-blue-800 {
  background-color: #ffffff;
}
.color-block.bg-blue-900 {
  color: #ffffff;
}
.color-block.text-blue-900 {
  background-color: #ffffff;
}
.color-block.bg-indigo-100 {
  color: #ffffff;
}
.color-block.text-indigo-100 {
  background-color: #ffffff;
}
.color-block.bg-indigo-200 {
  color: #ffffff;
}
.color-block.text-indigo-200 {
  background-color: #ffffff;
}
.color-block.bg-indigo-300 {
  color: #ffffff;
}
.color-block.text-indigo-300 {
  background-color: #ffffff;
}
.color-block.bg-indigo-400 {
  color: #ffffff;
}
.color-block.text-indigo-400 {
  background-color: #ffffff;
}
.color-block.bg-indigo-500 {
  color: #ffffff;
}
.color-block.text-indigo-500 {
  background-color: #ffffff;
}
.color-block.bg-indigo-600 {
  color: #ffffff;
}
.color-block.text-indigo-600 {
  background-color: #ffffff;
}
.color-block.bg-indigo-700 {
  color: #ffffff;
}
.color-block.text-indigo-700 {
  background-color: #ffffff;
}
.color-block.bg-indigo-800 {
  color: #ffffff;
}
.color-block.text-indigo-800 {
  background-color: #ffffff;
}
.color-block.bg-indigo-900 {
  color: #ffffff;
}
.color-block.text-indigo-900 {
  background-color: #ffffff;
}
.color-block.bg-purple-100 {
  color: #ffffff;
}
.color-block.text-purple-100 {
  background-color: #ffffff;
}
.color-block.bg-purple-200 {
  color: #ffffff;
}
.color-block.text-purple-200 {
  background-color: #ffffff;
}
.color-block.bg-purple-300 {
  color: #ffffff;
}
.color-block.text-purple-300 {
  background-color: #ffffff;
}
.color-block.bg-purple-400 {
  color: #ffffff;
}
.color-block.text-purple-400 {
  background-color: #ffffff;
}
.color-block.bg-purple-500 {
  color: #ffffff;
}
.color-block.text-purple-500 {
  background-color: #ffffff;
}
.color-block.bg-purple-600 {
  color: #ffffff;
}
.color-block.text-purple-600 {
  background-color: #ffffff;
}
.color-block.bg-purple-700 {
  color: #ffffff;
}
.color-block.text-purple-700 {
  background-color: #ffffff;
}
.color-block.bg-purple-800 {
  color: #ffffff;
}
.color-block.text-purple-800 {
  background-color: #ffffff;
}
.color-block.bg-purple-900 {
  color: #ffffff;
}
.color-block.text-purple-900 {
  background-color: #ffffff;
}
.color-block.bg-pink-100 {
  color: #212529;
}
.color-block.text-pink-100 {
  background-color: #212529;
}
.color-block.bg-pink-200 {
  color: #ffffff;
}
.color-block.text-pink-200 {
  background-color: #ffffff;
}
.color-block.bg-pink-300 {
  color: #ffffff;
}
.color-block.text-pink-300 {
  background-color: #ffffff;
}
.color-block.bg-pink-400 {
  color: #ffffff;
}
.color-block.text-pink-400 {
  background-color: #ffffff;
}
.color-block.bg-pink-500 {
  color: #ffffff;
}
.color-block.text-pink-500 {
  background-color: #ffffff;
}
.color-block.bg-pink-600 {
  color: #ffffff;
}
.color-block.text-pink-600 {
  background-color: #ffffff;
}
.color-block.bg-pink-700 {
  color: #ffffff;
}
.color-block.text-pink-700 {
  background-color: #ffffff;
}
.color-block.bg-pink-800 {
  color: #ffffff;
}
.color-block.text-pink-800 {
  background-color: #ffffff;
}
.color-block.bg-pink-900 {
  color: #ffffff;
}
.color-block.text-pink-900 {
  background-color: #ffffff;
}
.color-block.bg-red-100 {
  color: #212529;
}
.color-block.text-red-100 {
  background-color: #212529;
}
.color-block.bg-red-200 {
  color: #ffffff;
}
.color-block.text-red-200 {
  background-color: #ffffff;
}
.color-block.bg-red-300 {
  color: #ffffff;
}
.color-block.text-red-300 {
  background-color: #ffffff;
}
.color-block.bg-red-400 {
  color: #ffffff;
}
.color-block.text-red-400 {
  background-color: #ffffff;
}
.color-block.bg-red-500 {
  color: #ffffff;
}
.color-block.text-red-500 {
  background-color: #ffffff;
}
.color-block.bg-red-600 {
  color: #ffffff;
}
.color-block.text-red-600 {
  background-color: #ffffff;
}
.color-block.bg-red-700 {
  color: #ffffff;
}
.color-block.text-red-700 {
  background-color: #ffffff;
}
.color-block.bg-red-800 {
  color: #ffffff;
}
.color-block.text-red-800 {
  background-color: #ffffff;
}
.color-block.bg-red-900 {
  color: #ffffff;
}
.color-block.text-red-900 {
  background-color: #ffffff;
}
.color-block.bg-orange-100 {
  color: #212529;
}
.color-block.text-orange-100 {
  background-color: #212529;
}
.color-block.bg-orange-200 {
  color: #ffffff;
}
.color-block.text-orange-200 {
  background-color: #ffffff;
}
.color-block.bg-orange-300 {
  color: #ffffff;
}
.color-block.text-orange-300 {
  background-color: #ffffff;
}
.color-block.bg-orange-400 {
  color: #ffffff;
}
.color-block.text-orange-400 {
  background-color: #ffffff;
}
.color-block.bg-orange-500 {
  color: #ffffff;
}
.color-block.text-orange-500 {
  background-color: #ffffff;
}
.color-block.bg-orange-600 {
  color: #ffffff;
}
.color-block.text-orange-600 {
  background-color: #ffffff;
}
.color-block.bg-orange-700 {
  color: #ffffff;
}
.color-block.text-orange-700 {
  background-color: #ffffff;
}
.color-block.bg-orange-800 {
  color: #ffffff;
}
.color-block.text-orange-800 {
  background-color: #ffffff;
}
.color-block.bg-orange-900 {
  color: #ffffff;
}
.color-block.text-orange-900 {
  background-color: #ffffff;
}
.color-block.bg-yellow-100 {
  color: #212529;
}
.color-block.text-yellow-100 {
  background-color: #212529;
}
.color-block.bg-yellow-200 {
  color: #212529;
}
.color-block.text-yellow-200 {
  background-color: #212529;
}
.color-block.bg-yellow-300 {
  color: #212529;
}
.color-block.text-yellow-300 {
  background-color: #212529;
}
.color-block.bg-yellow-400 {
  color: #212529;
}
.color-block.text-yellow-400 {
  background-color: #212529;
}
.color-block.bg-yellow-500 {
  color: #ffffff;
}
.color-block.text-yellow-500 {
  background-color: #ffffff;
}
.color-block.bg-yellow-600 {
  color: #ffffff;
}
.color-block.text-yellow-600 {
  background-color: #ffffff;
}
.color-block.bg-yellow-700 {
  color: #ffffff;
}
.color-block.text-yellow-700 {
  background-color: #ffffff;
}
.color-block.bg-yellow-800 {
  color: #ffffff;
}
.color-block.text-yellow-800 {
  background-color: #ffffff;
}
.color-block.bg-yellow-900 {
  color: #ffffff;
}
.color-block.text-yellow-900 {
  background-color: #ffffff;
}
.color-block.bg-green-100 {
  color: #212529;
}
.color-block.text-green-100 {
  background-color: #212529;
}
.color-block.bg-green-200 {
  color: #212529;
}
.color-block.text-green-200 {
  background-color: #212529;
}
.color-block.bg-green-300 {
  color: #ffffff;
}
.color-block.text-green-300 {
  background-color: #ffffff;
}
.color-block.bg-green-400 {
  color: #ffffff;
}
.color-block.text-green-400 {
  background-color: #ffffff;
}
.color-block.bg-green-500 {
  color: #ffffff;
}
.color-block.text-green-500 {
  background-color: #ffffff;
}
.color-block.bg-green-600 {
  color: #ffffff;
}
.color-block.text-green-600 {
  background-color: #ffffff;
}
.color-block.bg-green-700 {
  color: #ffffff;
}
.color-block.text-green-700 {
  background-color: #ffffff;
}
.color-block.bg-green-800 {
  color: #ffffff;
}
.color-block.text-green-800 {
  background-color: #ffffff;
}
.color-block.bg-green-900 {
  color: #ffffff;
}
.color-block.text-green-900 {
  background-color: #ffffff;
}
.color-block.bg-teal-100 {
  color: #212529;
}
.color-block.text-teal-100 {
  background-color: #212529;
}
.color-block.bg-teal-200 {
  color: #212529;
}
.color-block.text-teal-200 {
  background-color: #212529;
}
.color-block.bg-teal-300 {
  color: #ffffff;
}
.color-block.text-teal-300 {
  background-color: #ffffff;
}
.color-block.bg-teal-400 {
  color: #ffffff;
}
.color-block.text-teal-400 {
  background-color: #ffffff;
}
.color-block.bg-teal-500 {
  color: #ffffff;
}
.color-block.text-teal-500 {
  background-color: #ffffff;
}
.color-block.bg-teal-600 {
  color: #ffffff;
}
.color-block.text-teal-600 {
  background-color: #ffffff;
}
.color-block.bg-teal-700 {
  color: #ffffff;
}
.color-block.text-teal-700 {
  background-color: #ffffff;
}
.color-block.bg-teal-800 {
  color: #ffffff;
}
.color-block.text-teal-800 {
  background-color: #ffffff;
}
.color-block.bg-teal-900 {
  color: #ffffff;
}
.color-block.text-teal-900 {
  background-color: #ffffff;
}
.color-block.bg-cyan-100 {
  color: #212529;
}
.color-block.text-cyan-100 {
  background-color: #212529;
}
.color-block.bg-cyan-200 {
  color: #212529;
}
.color-block.text-cyan-200 {
  background-color: #212529;
}
.color-block.bg-cyan-300 {
  color: #ffffff;
}
.color-block.text-cyan-300 {
  background-color: #ffffff;
}
.color-block.bg-cyan-400 {
  color: #ffffff;
}
.color-block.text-cyan-400 {
  background-color: #ffffff;
}
.color-block.bg-cyan-500 {
  color: #ffffff;
}
.color-block.text-cyan-500 {
  background-color: #ffffff;
}
.color-block.bg-cyan-600 {
  color: #ffffff;
}
.color-block.text-cyan-600 {
  background-color: #ffffff;
}
.color-block.bg-cyan-700 {
  color: #ffffff;
}
.color-block.text-cyan-700 {
  background-color: #ffffff;
}
.color-block.bg-cyan-800 {
  color: #ffffff;
}
.color-block.text-cyan-800 {
  background-color: #ffffff;
}
.color-block.bg-cyan-900 {
  color: #ffffff;
}
.color-block.text-cyan-900 {
  background-color: #ffffff;
}
.color-block.bg-gray-100 {
  color: #212529;
}
.color-block.text-gray-100 {
  background-color: #212529;
}
.color-block.bg-gray-200 {
  color: #212529;
}
.color-block.text-gray-200 {
  background-color: #212529;
}
.color-block.bg-gray-300 {
  color: #212529;
}
.color-block.text-gray-300 {
  background-color: #212529;
}
.color-block.bg-gray-400 {
  color: #212529;
}
.color-block.text-gray-400 {
  background-color: #212529;
}
.color-block.bg-gray-500 {
  color: #ffffff;
}
.color-block.text-gray-500 {
  background-color: #ffffff;
}
.color-block.bg-gray-600 {
  color: #ffffff;
}
.color-block.text-gray-600 {
  background-color: #ffffff;
}
.color-block.bg-gray-700 {
  color: #ffffff;
}
.color-block.text-gray-700 {
  background-color: #ffffff;
}
.color-block.bg-gray-800 {
  color: #ffffff;
}
.color-block.text-gray-800 {
  background-color: #ffffff;
}
.color-block.bg-gray-900 {
  color: #ffffff;
}
.color-block.text-gray-900 {
  background-color: #ffffff;
}

.card .toast-container {
  position: relative;
  z-index: 99;
}

.pc-sidebar {
  background: #fff;
  width: 260px;
  position: fixed;
  top: 80px;
  bottom: 0;
  z-index: 1026;
  overflow: hidden;
}
.pc-sidebar .navbar-wrapper {
  width: 260px;
  background: inherit;
}
.pc-sidebar ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.pc-sidebar .pc-caption {
  color: #212121;
  display: block;
  padding: 24px 20px 10px !important;
  text-transform: capitalize;
  position: relative;
  font-size: 14px;
  font-weight: 500;
}
.pc-sidebar .pc-caption i {
  display: none;
}
.pc-sidebar .pc-caption:not(:first-child):after {
  content: "";
  position: absolute;
  top: 5px;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  border-top: 1px solid #eee;
}
.pc-sidebar .pc-caption label {
  margin-bottom: 0;
}
.pc-sidebar .pc-caption span:not(.badge) {
  display: block;
  color: #67758a;
  font-size: 14px;
  font-weight: 500;
}
.pc-sidebar .pc-micon {
  margin-right: 15px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}
.pc-sidebar .pc-micon i {
  font-size: 18px;
}
.pc-sidebar .pc-micon i.material-icons-two-tone {
  font-size: 22px;
  display: inline-block;
}
.pc-sidebar .pc-micon > svg {
  width: 18px;
  height: 18px;
  fill: rgba(114, 103, 239, 0.2);
}
.pc-sidebar .pc-link {
  display: block;
  padding: 11px 20px;
  color: #616161;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}
.pc-sidebar .pc-link:active, .pc-sidebar .pc-link:focus, .pc-sidebar .pc-link:hover {
  text-decoration: none;
  color: #6610F2;
}
.pc-sidebar .pc-link:active .pc-micon i,
.pc-sidebar .pc-link:active .pc-micon svg, .pc-sidebar .pc-link:focus .pc-micon i,
.pc-sidebar .pc-link:focus .pc-micon svg, .pc-sidebar .pc-link:hover .pc-micon i,
.pc-sidebar .pc-link:hover .pc-micon svg {
  color: #6610F2;
}
.pc-sidebar .pc-item {
  padding-bottom: 2px;
}
.pc-sidebar .pc-item > .pc-link {
  margin-left: 16px;
  margin-right: 16px;
}
.pc-sidebar .pc-item.disabled a {
  cursor: not-allowed;
  user-select: none;
}
.pc-sidebar .pc-item.active > .pc-link, .pc-sidebar .pc-item:focus > .pc-link, .pc-sidebar .pc-item:hover > .pc-link {
  color: #6610F2;
}
.pc-sidebar .pc-item.active > .pc-link .pc-micon i,
.pc-sidebar .pc-item.active > .pc-link .pc-micon svg, .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
.pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
.pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #6610F2;
}
.pc-sidebar .pc-arrow {
  float: right;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}
.pc-sidebar .pc-arrow > svg {
  width: 14px;
  height: 14px;
}
.pc-sidebar .pc-badge {
  margin-left: 8px;
}
.pc-sidebar .pc-navbar-card {
  position: relative;
  overflow: hidden;
  padding: 24px;
  margin: 20px;
}
.pc-sidebar .pc-navbar-card::before {
  border: 3px solid;
  top: 145px;
  right: -70px;
}
.pc-sidebar .pc-navbar-card::after {
  border: 19px solid;
  top: 65px;
  right: -150px;
}
.pc-sidebar .pc-navbar-card::before, .pc-sidebar .pc-navbar-card::after {
  content: "";
  position: absolute;
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.pc-sidebar .m-header {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 16px 24px;
}
.pc-sidebar .m-header .logo-sm {
  display: none;
}
.pc-sidebar .navbar-content {
  position: relative;
  height: calc(100vh - 80px);
  padding: 10px 0;
}
.pc-sidebar .pc-submenu .pc-link {
  padding: 10px 30px 10px 55px;
}
.pc-sidebar .pc-submenu .pc-submenu .pc-link {
  padding: 10px 30px 10px 75px;
}
.pc-sidebar .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 10px 30px 10px 95px;
}
.pc-sidebar .pc-submenu {
  position: relative;
}
.pc-sidebar .pc-submenu .pc-item {
  position: relative;
}
.pc-sidebar .pc-submenu .pc-item:before {
  content: "";
  position: absolute;
  left: 55px;
  top: 17px;
  z-index: 1;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.pc-sidebar .pc-submenu .pc-item:hover:before, .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #6610F2;
  transform: scale(1.25);
}
.pc-sidebar .pc-submenu .pc-submenu > .pc-item:before {
  left: 75px;
}
.pc-sidebar .pc-submenu .pc-submenu .pc-submenu > .pc-item:before {
  left: 95px;
}
.pc-sidebar .pc-navbar > li > .pc-submenu::before {
  content: "";
  position: absolute;
  left: 40px;
  top: 0;
  z-index: 1;
  width: 1px;
  height: 100%;
  border-radius: 2px;
  background: #d4e7f9;
}
.pc-sidebar .pc-navbar > .pc-item.active > .pc-link, .pc-sidebar .pc-navbar > .pc-item:focus > .pc-link, .pc-sidebar .pc-navbar > .pc-item:hover > .pc-link {
  background: #e0cffc;
  color: #6610F2;
}
.pc-sidebar .pc-navbar > .pc-item.active > .pc-link i, .pc-sidebar .pc-navbar > .pc-item:focus > .pc-link i, .pc-sidebar .pc-navbar > .pc-item:hover > .pc-link i {
  color: #6610F2;
}
.pc-sidebar .pc-hasmenu.pc-trigger > .pc-submenu {
  display: block;
}
.pc-sidebar .pc-hasmenu.pc-trigger > .pc-link > .pc-arrow {
  transform: rotate(90deg);
}

.pc-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}

@media (min-width: 1025px) {
  .navbar-overlay .pc-sidebar.pc-over-menu-active .navbar-wrapper {
    position: relative;
    z-index: 5;
  }
  .navbar-overlay .pc-sidebar:not(.pc-over-menu-active) {
    left: -260px;
    box-shadow: none;
  }

  .pc-sidebar {
    transition: width 0.15s ease;
  }
  .pc-sidebar .m-header {
    display: none;
  }
  .pc-sidebar ~ .pc-container {
    transition: margin-left 0.15s ease;
  }
  .pc-sidebar.pc-sidebar-hide {
    width: 0;
  }
  .pc-sidebar.pc-sidebar-hide ~ .pc-container {
    margin-left: 20px;
  }
}
@media (max-width: 1024px) {
  .pc-sidebar {
    left: -260px;
    box-shadow: none;
    top: 0;
    transition: all 0.15s ease-in-out;
  }
  .pc-sidebar.mob-sidebar-active {
    left: 0;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  }
  .pc-sidebar.mob-sidebar-active .navbar-wrapper {
    position: relative;
    z-index: 5;
    background: inherit;
  }

  .minimenu .pc-container {
    margin-left: 0;
  }
}
.pc-header {
  background: #fff;
  color: #616161;
  min-height: 80px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1025;
  display: flex;
}
.pc-header ul {
  margin-bottom: 0;
  display: inline-flex;
}
.pc-header .m-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  padding: 16px 10px 16px 24px;
}
.pc-header .header-wrapper {
  display: flex;
  padding: 0 20px;
  flex-grow: 1;
}
.pc-header .header-search {
  position: relative;
}
.pc-header .header-search .form-control {
  border-radius: 8px;
  padding: 0.813rem 3rem 0.813rem 3rem;
  width: 434px;
  max-width: 100%;
}
@media (max-width: 1024px) {
  .pc-header .header-search .form-control {
    width: 100%;
  }
}
.pc-header .header-search .icon-search {
  font-size: 18px;
  position: absolute;
  top: 15px;
  left: 15px;
}
.pc-header .header-search .btn-search {
  position: absolute;
  top: 7px;
  right: 9px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 8px;
}
.pc-header .pc-h-item {
  min-height: 80px;
  display: flex;
  align-items: center;
  position: relative;
}
.pc-header .pc-head-link {
  color: #616161;
  margin: 0 16px;
  position: relative;
  font-weight: 500;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 8px;
}
.pc-header .pc-head-link.head-link-primary {
  background: #d4e7f9;
  color: #2689E2;
}
.pc-header .pc-head-link.head-link-primary > i {
  color: #2689E2;
}
.pc-header .pc-head-link.head-link-primary:hover {
  background: #2689E2;
  color: #fff;
}
.pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
.pc-header .pc-head-link.head-link-secondary {
  background: #e0cffc;
  color: #6610F2;
}
.pc-header .pc-head-link.head-link-secondary > i {
  color: #6610F2;
}
.pc-header .pc-head-link.head-link-secondary:hover {
  background: #6610F2;
  color: #fff;
}
.pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
.pc-header .pc-head-link > i {
  font-size: 20px;
  color: #2689E2;
}
.pc-header .pc-head-link > i.material-icons-two-tone {
  font-size: 24px;
  background-color: #616161;
}
.pc-header .pc-head-link > svg {
  width: 20px;
  height: 20px;
}
.pc-header .pc-head-link.active, .pc-header .pc-head-link:active, .pc-header .pc-head-link:focus, .pc-header .pc-head-link:hover {
  text-decoration: none;
  color: #6610F2;
  background: #e0cffc;
}
.pc-header .pc-head-link.active .hamburger .hamburger-inner, .pc-header .pc-head-link:active .hamburger .hamburger-inner, .pc-header .pc-head-link:focus .hamburger .hamburger-inner, .pc-header .pc-head-link:hover .hamburger .hamburger-inner {
  background-color: #6610F2;
}
.pc-header .pc-head-link.active .hamburger .hamburger-inner::after, .pc-header .pc-head-link.active .hamburger .hamburger-inner::before, .pc-header .pc-head-link:active .hamburger .hamburger-inner::after, .pc-header .pc-head-link:active .hamburger .hamburger-inner::before, .pc-header .pc-head-link:focus .hamburger .hamburger-inner::after, .pc-header .pc-head-link:focus .hamburger .hamburger-inner::before, .pc-header .pc-head-link:hover .hamburger .hamburger-inner::after, .pc-header .pc-head-link:hover .hamburger .hamburger-inner::before {
  background-color: #6610F2;
}
.pc-header .pc-head-link.active i.material-icons-two-tone, .pc-header .pc-head-link:active i.material-icons-two-tone, .pc-header .pc-head-link:focus i.material-icons-two-tone, .pc-header .pc-head-link:hover i.material-icons-two-tone {
  background-color: #6610F2;
}
.pc-header .pc-head-link .pc-h-badge {
  position: absolute;
  top: 3px;
  right: 10px;
  border-radius: 50%;
  font-size: 9px;
}
.pc-header .pc-head-link .pc-h-badge.dots {
  width: 9px;
  height: 9px;
  top: 7px;
  right: 16px;
  padding: 0;
}
.pc-header .pc-head-link .user-desc,
.pc-header .pc-head-link .user-name {
  display: block;
  line-height: 1;
}
.pc-header .pc-head-link .user-name {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
}
.pc-header .pc-head-link .user-desc {
  font-size: 12px;
  font-weight: 400;
  color: rgba(97, 97, 97, 0.7);
}
.pc-header .pc-h-dropdown {
  transform: none !important;
  top: 100% !important;
}
.pc-header .pc-h-dropdown.dropdown-menu-end {
  right: 0 !important;
  left: auto !important;
}
.pc-header .pc-level-menu {
  position: relative;
}
.pc-header .pc-level-menu:hover > .dropdown-menu {
  display: block;
  left: 100%;
  top: -18px !important;
}
.pc-header .dropdown .dropdown-item small, .pc-header .dropdown .dropdown-item .small {
  opacity: 0.8;
  margin: 0 4px;
}
.pc-header .pc-mega-menu {
  position: static;
}
.pc-header .pc-mega-menu .pc-mega-dmenu {
  transform: none !important;
  left: calc(260px + 20px) !important;
  right: 40px !important;
  top: 100% !important;
  padding: 0;
}
.pc-header .pc-mega-menu .pc-mega-dmenu .row.g-0 .col {
  padding: 15px;
  border-right: 1px dashed #eeeeee;
}
.pc-header .pc-mega-menu .pc-mega-dmenu .mega-title {
  margin: 10px 25px;
  position: relative;
}
.pc-header .pc-mega-menu .pc-mega-dmenu .pc-mega-list {
  display: block;
  list-style: none;
  padding-left: 0;
}
.pc-header .drp-search {
  min-width: 20rem;
}
.pc-header #vertical-nav-toggle {
  margin-left: -15px;
}
.pc-header .pc-cart-menu .drp-cart {
  min-width: 20rem;
  padding: 0;
}
.pc-header .pc-cart-menu .drp-cart .cart-head {
  border-radius: 2px 2px 0 0;
  padding: 20px;
  background: rgba(212, 231, 249, 0.2);
  border-bottom: 1px solid #eeeeee;
}
.pc-header .pc-cart-menu .drp-cart .cart-item {
  display: flex;
  padding: 10px 20px;
}
.pc-header .pc-cart-menu .drp-cart .cart-item + .cart-item {
  border-top: 1px dashed #eeeeee;
}
.pc-header .pc-cart-menu .drp-cart .cart-item img {
  width: 60px;
}
.pc-header .pc-cart-menu .drp-cart .cart-item .cart-desc {
  flex: 1;
}
.pc-header .pc-cart-menu .drp-cart .cart-item .p-star {
  fill: currentColor;
}
.pc-header .pc-cart-menu .drp-cart .cart-item.table-responsive {
  border-top: 1px solid #eeeeee;
  background: rgba(212, 231, 249, 0.2);
}
.pc-header .pc-cart-menu .drp-cart .cart-item svg {
  width: 14px;
  height: 14px;
}
.pc-header .user-avtar {
  width: 40px;
  margin-right: 10px;
  border-radius: 50%;
}
.pc-header .header-user-profile .pc-head-link {
  height: 48px;
  width: 92px;
  border-radius: 50px;
}
.pc-header .header-user-profile .pc-head-link > span > i {
  font-size: 22px;
  margin-right: 8px;
}
.pc-header .header-user-profile .pc-head-link .user-avtar {
  width: 34px;
}
@media (max-width: 575.98px) {
  .pc-header .header-user-profile .pc-head-link {
    width: 48px;
  }
  .pc-header .header-user-profile .pc-head-link .user-avtar {
    margin-right: 0;
  }
  .pc-header .header-user-profile .pc-head-link > span > i {
    display: none;
  }
}
.pc-header .dropdown-user-profile {
  min-width: 330px;
  max-width: 100%;
}
.pc-header .dropdown-user-profile .dropdown-item {
  border-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.pc-header .dropdown-user-profile .dropdown-item:hover {
  background: #e0cffc;
  color: #6610F2;
}
.pc-header .dropdown-user-profile .upgradeplan-block {
  position: relative;
  overflow: hidden;
  padding: 24px;
}
.pc-header .dropdown-user-profile .upgradeplan-block::before, .pc-header .dropdown-user-profile .upgradeplan-block::after {
  content: "";
  position: absolute;
  border-color: #FFC107;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.pc-header .dropdown-user-profile .upgradeplan-block::before {
  border: 3px solid;
  top: 145px;
  right: -70px;
}
.pc-header .dropdown-user-profile .upgradeplan-block::after {
  border: 19px solid;
  top: 65px;
  right: -150px;
}
.pc-header .dropdown-user-profile .settings-block {
  padding: 24px;
}
.pc-header .dropdown-user-profile .settings-block .form-switch {
  padding-left: 0;
  margin-bottom: 0;
}
.pc-header .dropdown-user-profile .settings-block .form-switch + .form-switch {
  margin-top: 15px;
}
.pc-header .dropdown-user-profile .settings-block .form-switch .form-check-input {
  float: right;
}
.pc-header .dropdown-user-profile .settings-block .form-switch .form-check-label {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
}
.pc-header .dropdown-notification {
  min-width: 330px;
  max-width: 100%;
}
.pc-header .dropdown-notification .list-group-item-action:active, .pc-header .dropdown-notification .list-group-item-action:hover, .pc-header .dropdown-notification .list-group-item-action:focus {
  background: #d4e7f9;
}
.pc-header .dropdown-notification .list-group-item-action .user-avtar,
.pc-header .dropdown-notification .list-group-item-action h5,
.pc-header .dropdown-notification .list-group-item-action .h5 {
  cursor: pointer;
}
.pc-header .dropdown-notification .badge {
  font-size: 0.8125rem;
  padding: 0.43em 1em;
}
.pc-header .dropdown-notification .user-avtar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.pc-header .dropdown-notification .notification-file {
  display: flex;
  align-items: center;
}
.pc-header .dropdown-notification .notification-file i {
  font-size: 20px;
  margin-right: 16px;
}

.pc-mob-header {
  display: none;
  background: #161c25;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1026;
  left: 0;
  right: 0;
  padding: 0 10px 0 30px;
}
.pc-mob-header .hamburger-inner,
.pc-mob-header .hamburger-inner::after,
.pc-mob-header .hamburger-inner::before {
  background: #fff;
}
.pc-mob-header .pc-head-link,
.pc-mob-header a {
  color: #fff;
}

.notification-modal.modal.fade .modal-dialog {
  transform: translate(100%, 0);
}

.notification-modal.modal.show .modal-dialog {
  transform: none;
}

.notification-modal {
  padding-right: 0 !important;
}
.notification-modal .modal-dialog {
  margin: 0 0 0 auto;
}
.notification-modal .modal-content {
  min-height: 100vh;
  border: none;
  border-radius: 0;
  box-shadow: -1px 0 9px -3px rgba(0, 0, 0, 0.6);
}

@media (min-width: 1025px) {
  .pc-header {
    z-index: 1027;
  }
  .pc-header .header-mobile-collapse {
    display: none;
  }
}
@media (max-width: 1024px) {
  .pc-mob-header {
    display: none;
  }

  .pc-header {
    top: 0;
    left: 0;
    transition: all 0.15s ease-in-out;
  }
  .pc-header .m-header {
    display: none;
  }
  .pc-header .pc-head-link .user-desc,
.pc-header .pc-head-link .user-name {
    display: none;
  }
  .pc-header .pcm-logo {
    margin-left: 30px;
  }
  .pc-header .pcm-logo img {
    max-width: 160px;
  }
  .pc-header.mob-header-active {
    top: 80px;
  }
  .pc-header.mob-header-active .header-wrapper {
    background: #fff;
    position: relative;
    z-index: 5;
  }
  .pc-header .pc-md-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  .pc-header .pc-level-menu .dropdown-menu {
    left: 0;
    display: block;
    padding-left: 30px;
  }
  .pc-header .pc-mega-menu .pc-mega-dmenu .row.g-0 > .col.image-block {
    display: none;
  }
  .pc-header .pc-mob-drp.mob-drp-active .pc-h-item {
    display: block;
    min-height: auto;
    position: relative;
  }
  .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .pc-head-link {
    display: block;
    margin: 5px 10px !important;
  }
  .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .dropdown-menu {
    position: relative !important;
    width: 100%;
    float: none;
    box-shadow: none;
  }
  .pc-header .pc-mob-drp.mob-drp-active ul {
    display: block;
  }

  .minimenu .pc-header {
    left: 0;
  }
}
#pc-noti-home > .media:hover {
  margin: -15px;
  padding: 15px;
  background: rgba(38, 137, 226, 0.1);
}

@media (max-width: 767.98px) {
  .pc-mega-menu .pc-mega-dmenu {
    max-height: calc(100vh - 80px);
    overflow-x: auto;
  }
  .pc-mega-menu .pc-mega-dmenu .row.g-0 > .col {
    flex: 100%;
  }
}
@media (max-width: 575.98px) {
  .pc-header .pc-head-link {
    padding: 0.65rem;
    margin: 0 8px;
  }
  .pc-header .pc-h-item {
    position: static;
  }
  .pc-header .pc-h-item .pc-h-dropdown {
    left: 0 !important;
    right: 0 !important;
  }
}
.pc-footer {
  position: relative;
  z-index: 999;
  margin-left: 260px;
  margin-right: 20px;
  margin-top: 80px;
  padding: 15px 0;
}
.pc-footer .footer-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}
.pc-footer .footer-link .list-inline-item:not(:last-child) {
  margin-right: 0.9rem;
}
@media (max-width: 1024px) {
  .pc-footer {
    margin-left: 20px;
  }
}

body {
  background-color: #fff;
}

.pc-container {
  position: relative;
  top: 80px;
  margin-left: 260px;
  margin-right: 20px;
  min-height: calc(100vh - 141px);
  background: #eceff1;
  border-radius: 8px;
}
.pc-container .pc-content {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.pc-container .page-header + .row {
  padding-top: 24px;
}
.pc-container .page-header + .pc-content {
  padding-top: 85px;
}

.page-header {
  display: flex;
  align-items: center;
  top: 80px;
  left: 260px;
  right: 0;
  z-index: 1023;
  min-height: 55px;
  padding: 13px 25px;
  box-shadow: none;
  background: #ffffff;
  border-radius: 8px;
}
.page-header .page-block {
  width: 100%;
}
.page-header .page-header-title {
  display: inline-block;
}
.page-header h5, .page-header .h5 {
  margin-bottom: 0;
  margin-right: 8px;
  padding-right: 8px;
  font-weight: 500;
  border-right: 1px solid #cfd6db;
}
.page-header .breadcrumb {
  padding: 0;
  display: inline-flex;
  margin-bottom: 0;
  background: transparent;
  font-size: 13px;
}
.page-header .breadcrumb a {
  color: #212529;
}
.page-header .breadcrumb .breadcrumb-item a:hover {
  color: #2689E2;
}
.page-header .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  position: relative;
  top: 2px;
}
.page-header .breadcrumb .breadcrumb-item:last-child {
  opacity: 0.75;
}
.page-header .breadcrumb svg {
  width: 14px;
  height: 14px;
  vertical-align: baseline;
}

.upgrade-card .card-body {
  padding-bottom: 100px;
  z-index: 1;
  position: relative;
}
.upgrade-card > .container {
  z-index: 5;
  position: relative;
  margin-top: -60px;
}
.upgrade-card .up-price-table td {
  border-color: #eceff1;
}
.upgrade-card .up-price-table th {
  border: none;
}
.upgrade-card .up-price-table th + th {
  border-radius: 5px 5px 0 0;
  padding: 20px 0;
}
.upgrade-card .up-price-table tbody tr:first-child td {
  border-top: none;
}
.upgrade-card .up-price-table td,
.upgrade-card .up-price-table th {
  text-align: right;
}
.upgrade-card .up-price-table td + td,
.upgrade-card .up-price-table td + th,
.upgrade-card .up-price-table th + td,
.upgrade-card .up-price-table th + th {
  text-align: center;
}
.upgrade-card .up-price-table td + td,
.upgrade-card .up-price-table th + td {
  border-left: 1px solid #eceff1;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition: filter 0.15s linear;
  width: 20px;
  height: 20px;
}
.hamburger.is-active .hamburger-inner {
  background-color: #000;
}
.hamburger.is-active .hamburger-inner::after, .hamburger.is-active .hamburger-inner::before {
  background-color: #000;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 18px;
  height: 2px;
  background-color: #616161;
  border-radius: 4px;
  position: absolute;
  transition: transform 0.15s ease;
}
.hamburger-inner::after, .hamburger-inner::before {
  width: 18px;
  height: 2px;
  background-color: #616161;
  border-radius: 4px;
  position: absolute;
  transition: transform 0.15s ease;
}
.hamburger-inner::after, .hamburger-inner::before {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -6px;
}
.hamburger-inner::after {
  bottom: -6px;
}

.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(4px, 2px, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(4px, -2px, 0) rotate(-45deg) scale(0.7, 1);
}

@media (max-width: 1024px) {
  .pc-container {
    margin-left: 10px;
    margin-right: 10px;
  }
  .pc-container .page-header + .row,
.pc-container .page-header + .pc-content,
.pc-container .pc-content {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .page-header {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;
    margin-right: 10px;
  }

  .minimenu .page-header {
    left: 0;
  }
  .minimenu .pc-container {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .pc-container .pc-content {
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .pc-container .container {
    padding-left: 0;
    padding-right: 0;
  }

  .page-header {
    padding: 13px 20px;
  }
  .page-header h5, .page-header .h5 {
    padding-bottom: 5px;
    margin-bottom: 0;
    border-right: none;
    font-size: 14px;
  }
  .page-header .breadcrumb {
    display: flex;
    margin-bottom: 0;
    font-size: 11px;
  }
  .page-header .text-md-right {
    margin-top: 5px;
  }
}
.navbar-content {
  position: relative;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.simplebar-content-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before, .simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a3b1bb;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (min-width: 768px) {
  .footer-wrapper.container,
.pc-content.container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .footer-wrapper.container,
.pc-content.container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .footer-wrapper.container,
.pc-content.container {
    max-width: 960px;
  }
}
@media (min-width: 1400px) {
  .footer-wrapper.container,
.pc-content.container {
    max-width: 1140px;
  }
}

.dashnum-card {
  position: relative;
}
.dashnum-card .avtar:not([class*=bg-]) {
  background: rgba(0, 0, 0, 0.15);
}
.dashnum-card .card-body {
  position: relative;
  z-index: 5;
}
.dashnum-card .round {
  position: absolute;
  background: inherit;
  border-radius: 50%;
  width: 210px;
  overflow: hidden;
  height: 210px;
  z-index: 1;
}
.dashnum-card .round:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.17);
}
.dashnum-card .round.small {
  top: -125px;
  right: -15px;
  opacity: 0.5;
}
.dashnum-card .round.big {
  top: -85px;
  right: -95px;
}
.dashnum-card.dashnum-card-small .round[class*=bg-] {
  opacity: 0.5;
}
.dashnum-card.dashnum-card-small .round[class*=bg-].big:after {
  background: linear-gradient(210.04deg, #ffffff -50.94%, rgba(255, 255, 255, 0) 83.49%);
}
.dashnum-card.dashnum-card-small .round[class*=bg-].small:after {
  background: linear-gradient(140.9deg, #ffffff -14.02%, rgba(255, 255, 255, 0) 77.58%);
}
.dashnum-card.dashnum-card-small .round.small {
  top: -160px;
  right: -130px;
  opacity: 0.5;
}
.dashnum-card.dashnum-card-small .round.big {
  top: -30px;
  right: -180px;
}

.order-card {
  color: #fff;
  overflow: hidden;
}
.order-card .card-icon {
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 100px;
  opacity: 0.5;
}
.order-card .card-icon > svg {
  width: 100px;
  height: 100px;
}

.feed-card {
  overflow: hidden;
}
.feed-card h6, .feed-card .h6 {
  margin-top: 7px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.feed-card h6 > span, .feed-card .h6 > span {
  font-size: 11px;
}
.feed-card h6:hover, .feed-card .h6:hover {
  color: #2689E2;
}
.feed-card .feed-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px 9px;
}
.feed-card .card-body {
  position: relative;
  padding-left: 25px;
}
.feed-card .card-body.p-t-0.p-b-0 {
  padding-left: 12px;
}
.feed-card .card-body .border-feed {
  transition: all 0.3s ease-in-out;
  position: relative;
}
.feed-card .card-body .border-feed i {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.feed-card .card-body .border-feed i.material-icons-two-tone {
  background-color: #fff;
}
.feed-card:hover .border-feed.bg-primary {
  background-color: rgba(38, 137, 226, 0.3) !important;
}
.feed-card:hover .border-feed.bg-primary i {
  color: #2689E2;
}
.feed-card:hover .border-feed.bg-primary i.material-icons-two-tone {
  background-color: #2689E2;
}
.feed-card:hover .border-feed.bg-danger {
  background-color: rgba(244, 67, 54, 0.3) !important;
}
.feed-card:hover .border-feed.bg-danger i {
  color: #F44336;
}
.feed-card:hover .border-feed.bg-danger i.material-icons-two-tone {
  background-color: #F44336;
}
.feed-card:hover .border-feed.bg-success {
  background-color: rgba(0, 200, 83, 0.3) !important;
}
.feed-card:hover .border-feed.bg-success i {
  color: #00C853;
}
.feed-card:hover .border-feed.bg-success i.material-icons-two-tone {
  background-color: #00C853;
}
.feed-card:hover .border-feed.bg-warning {
  background-color: rgba(255, 193, 7, 0.3) !important;
}
.feed-card:hover .border-feed.bg-warning i {
  color: #FFC107;
}
.feed-card:hover .border-feed.bg-warning i.material-icons-two-tone {
  background-color: #FFC107;
}

.social-widget-card {
  border-top: none;
  color: #fff;
}
.social-widget-card i {
  position: absolute;
  right: 14px;
  top: 24px;
  font-size: 48px;
  opacity: 0.4;
  transition: all ease-in 0.3s;
}
.social-widget-card:hover i {
  opacity: 1;
  transform: scale(1.1);
}

.comp-card i {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 17px 0;
  font-size: 18px;
}

.proj-progress-card .progress {
  height: 6px;
  overflow: visible;
}
@media only screen and (max-width: 992px) {
  .proj-progress-card h6, .proj-progress-card .h6 {
    margin-top: 15px;
  }
  .proj-progress-card .progress {
    margin-bottom: 20px;
  }
}

.widget-visitor-card {
  overflow: hidden;
}
.widget-visitor-card i,
.widget-visitor-card svg {
  font-size: 80px;
  color: #fff;
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: -10px;
  opacity: 0.3;
  left: -10px;
  transform: rotate(15deg);
  transition: all 0.3s ease-in-out;
}
.widget-visitor-card:hover svg {
  transform: rotate(0deg) scale(1.4);
  opacity: 0.5;
}

.flat-card .row-table {
  display: table;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}
.flat-card .row-table:first-child {
  border-bottom: 1px solid #eeeeee;
}
.flat-card .row-table .br {
  border-right: 1px solid #eeeeee;
}
.flat-card .row-table .b-icons {
  width: 45px;
  height: 45px;
  border-radius: 14px;
  padding: 10px;
}
.flat-card .row-table .b-icons i {
  font-size: 26px;
}
.flat-card .row-table span {
  text-transform: uppercase;
  font-size: 12px;
}
.flat-card .row-table h5, .flat-card .row-table .h5 {
  display: block;
  margin-bottom: 0.3em;
  margin-right: 0;
}
.flat-card .row-table > [class*=col-] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
  width: 33.3333333333%;
}
.flat-card .row-table > [class*=col-] .row {
  display: flex;
  align-items: center;
}
@media (max-width: 575.98px) {
  .flat-card .row-table {
    display: block;
  }
  .flat-card .row-table > [class*=col-] {
    display: inline-block;
    width: calc(100% - 2px) !important;
    border-bottom: 1px solid #eeeeee;
  }
}
.flat-card[class*=widget-] .row-table {
  display: flex;
}

.to-do-list i {
  color: #ccc;
  font-size: 17px;
  opacity: 0;
}
.to-do-list:hover i {
  opacity: 1;
  transition: opacity ease-in 0.3s;
}
.to-do-list .form-check-input:checked ~ label {
  text-decoration: line-through;
  opacity: 0.6;
}

.latest-update-card .card-body {
  padding-top: 0;
}
.latest-update-card .card-body .latest-update-box {
  position: relative;
}
.latest-update-card .card-body .latest-update-box:after {
  content: "";
  position: absolute;
  background: #ebebeb;
  height: 100%;
  width: 3px;
  top: 0;
  left: 82px;
  z-index: 1;
}
.latest-update-card .card-body .latest-update-box .update-meta {
  z-index: 2;
}
.latest-update-card .card-body .latest-update-box .update-meta .update-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding: 7px 8px;
  font-size: 16px;
  color: #fff;
}
.latest-update-card .card-body .latest-update-box .update-meta .update-icon.ring {
  border-width: 3px;
  width: 15px;
  height: 15px;
  padding: 0;
  display: block;
  background: #fff;
}
.latest-update-card .card-body .latest-update-box .update-meta i.update-icon {
  margin-left: 4px;
  margin-right: 4px;
}
.latest-update-card .card-body .latest-update-box .update-meta img.update-icon {
  padding: 0;
  width: 40px;
  height: 40px;
}

.user-activity-card .u-img {
  position: relative;
}
.user-activity-card .u-img .cover-img {
  width: 40px;
  height: 40px;
}
.user-activity-card .u-img .profile-img {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -5px;
  right: -10px;
  font-size: 12px;
}

.latest-posts-card a {
  transition: all 0.3s ease-in-out;
}
.latest-posts-card a:hover h6, .latest-posts-card a:hover .h6 {
  color: #2689E2;
}
.latest-posts-card a ~ p {
  font-size: 11px;
}
.latest-posts-card .latest-posts-img {
  width: 90px;
  height: 80px;
}

.mrr-card {
  position: relative;
}
.mrr-card .bottom-chart {
  bottom: 0;
}

.widget-last-task .img-radius {
  margin-right: 10px;
  margin-bottom: 5px;
}
.widget-last-task span.float-right {
  font-size: 11px;
}

.client-map img {
  vertical-align: top;
  width: 90px;
}
.client-map .client-detail {
  margin-bottom: 20px;
}
.client-map .client-detail img {
  border-radius: 5px;
}
.client-map .client-profile {
  float: left;
}
.client-map .client-contain {
  display: inline-block;
  margin-left: 20px;
}
.client-map .client-contain h5, .client-map .client-contain .h5 {
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
}
.client-map .client-card-box {
  border: 1px solid #eeeeee;
  border-radius: 5px;
}
.client-map .client-card-box .client-border {
  border-right: 1px solid #eeeeee;
}
.client-map .client-card-box .client-border-card {
  border-top: 1px solid #eeeeee;
}

.marketing-card .table thead th {
  border-bottom: none;
  border-top: none;
}
.marketing-card .table tbody tr:first-child td {
  border-top: none;
}
.marketing-card .table tbody .marketing-header {
  background-color: rgba(236, 239, 241, 0.5);
}
.marketing-card .table tbody td {
  vertical-align: middle;
}
.marketing-card .table tbody td p {
  margin-bottom: 0;
  display: inline-block;
}
.marketing-card .table tbody td .table-contain {
  display: inline-block;
  margin-left: 10px;
}
.marketing-card .table tbody td span {
  font-weight: 600;
}
.marketing-card .table tbody td h6, .marketing-card .table tbody td .h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5;
}
.marketing-card .table tbody td .dropdown-toggle {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}
.marketing-card .table tbody td .dropdown-toggle i {
  margin-right: 0;
}
.marketing-card .table tbody td .dropdown-toggle:after {
  display: none;
}
.marketing-card .table tbody td .social-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  top: -10px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.marketing-card .table tbody td .btn-action {
  position: relative;
  padding: 6px 18px 6px 32px;
}
.marketing-card .table tbody td .btn-action:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  left: 12px;
  top: 10px;
}
.marketing-card .table tbody td .btn-action.btn-bg-primary:after {
  background-color: #2689E2;
}
.marketing-card .table tbody td .btn-action.btn-bg-danger:after {
  background-color: #F44336;
}
.marketing-card .table tbody td .btn-action.btn-bg-success:after {
  background-color: #00C853;
}
.marketing-card .table tbody td .btn-action.btn-bg-warning:after {
  background-color: #FFC107;
}
.marketing-card .table tbody td .btn-action.btn-bg-info:after {
  background-color: #3EC9D6;
}
.marketing-card .table tbody td .btn-action.btn-bg-info:after {
  background-color: #3EC9D6;
}
.marketing-card .marketing-card-footer .tab-cont > p {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: 600;
  margin-top: -8px;
}
.marketing-card .marketing-card-footer .tab-cont > p i {
  font-size: 20px;
  margin: 0 5px;
}

.testimonial-card .progress {
  height: 5px;
}
.testimonial-card .review-block .cust-img {
  width: 40px;
  height: 40px;
}
.testimonial-card .review-block > div {
  padding-top: 15px;
  padding-bottom: 9px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
}
.testimonial-card .review-block > div:hover {
  background-color: rgba(38, 137, 226, 0.1);
}

.new-cust-card img {
  width: 40px;
}
.new-cust-card h6, .new-cust-card .h6 {
  margin-bottom: 0;
}
.new-cust-card .align-middle {
  position: relative;
}
.new-cust-card .align-middle .status {
  position: absolute;
  right: 0;
  top: 19px;
  font-size: 13px;
}
.new-cust-card .align-middle .status.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00C853;
}

.review-card .review-block .cust-img {
  width: 50px;
  height: 50px;
}
.review-card .review-block > div {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
}
.review-card .review-block > div:hover {
  background-color: rgba(38, 137, 226, 0.1);
}

.latest-activity-card .card-body {
  padding-top: 0;
}
.latest-activity-card .card-body .latest-update-box {
  position: relative;
}
.latest-activity-card .card-body .latest-update-box .update-meta {
  z-index: 2;
  min-width: 170px;
}
.latest-activity-card .card-body .latest-update-box .update-meta .update-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 12px 13px;
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}
@media only screen and (max-width: 575px) {
  .latest-activity-card .card-body .latest-update-box .update-meta {
    z-index: 2;
    min-width: 100%;
    text-align: left !important;
    margin-bottom: 15px;
    border-top: 1px solid #f1f1f1;
    padding-top: 15px;
  }
}

.task-card .task-list {
  position: relative;
}
.task-card .task-list:after {
  content: "";
  position: absolute;
  background: #eeeeee;
  height: 100%;
  width: 2px;
  top: 0;
  left: 10px;
  z-index: 1;
}
.task-card .task-list:before {
  content: "";
  position: absolute;
  background: #eeeeee;
  height: 15px;
  width: 15px;
  bottom: -14px;
  left: 3px;
  z-index: 2;
  border-radius: 50%;
}
.task-card .task-list li {
  margin-bottom: 30px;
  padding-left: 30px;
  position: relative;
}
.task-card .task-list li .task-icon {
  position: absolute;
  left: 3px;
  top: 1px;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  min-width: 15px;
  min-height: 15px;
  z-index: 2;
}

.chat-card .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 10px;
  position: relative;
}
.chat-card .msg img {
  width: 60px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
}
.chat-card .received-chat .msg {
  background: #add2f4;
  border-radius: 0 5px 5px 5px;
}
.chat-card .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-bottom-color: #add2f4;
}
.chat-card .send-chat {
  text-align: right;
}
.chat-card .send-chat .msg {
  background: #2689E2;
  color: #fff;
  border-radius: 5px 0 5px 5px;
}
.chat-card .send-chat .msg:after {
  content: "";
  position: absolute;
  right: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-right-color: #2689E2;
}

.user-Messages-card .text-time {
  font-size: 11px;
}
.user-Messages-card .u-img {
  position: relative;
}
.user-Messages-card .u-img .profile-img {
  width: 40px;
  height: 40px;
}
.user-Messages-card .u-img .tot-msg {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
  right: -5px;
  background-color: #F44336;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  text-align: center;
}

.product-progress-card .progress {
  height: 6px;
}
.product-progress-card .pp-cont {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.product-progress-card .pp-cont:after {
  content: "";
  background: #d2d2d2;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -15px;
}
@media only screen and (max-width: 991px) {
  .product-progress-card .pp-cont {
    padding: 15px 0;
  }
  .product-progress-card .pp-cont:after {
    display: none;
  }
}
.product-progress-card .pp-main > div:first-child > .pp-cont:after {
  display: none;
}

.social-res-card .progress {
  height: 6px;
  border-radius: 4px;
}
.social-res-card .progress .progress-bar {
  border-radius: 4px;
}

.analytic-card .analytic-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 17px 0;
  border-radius: 50%;
  background-color: #fff;
}

.ticket-card .lbl-card {
  border-radius: 50px;
  padding: 5px 15px;
  display: inline-block;
}

.proj-t-card {
  position: relative;
  overflow: hidden;
}
.proj-t-card .pt-badge {
  margin-bottom: 0;
  display: inline-block;
  padding: 60px 50px 20px 20px;
  border-radius: 50%;
  position: absolute;
  top: -45px;
  right: -35px;
  transition: all 0.3s ease-in-out;
}

.prod-p-card i {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 11px 0;
  font-size: 29px;
}

.widget-primary-card.flat-card, .flat-card.widget-purple-card {
  border-top: none;
  background-color: #2689E2;
  color: #fff;
}
.widget-primary-card.flat-card h4, .flat-card.widget-purple-card h4, .widget-primary-card.flat-card .h4, .flat-card.widget-purple-card .h4,
.widget-primary-card.flat-card h6,
.flat-card.widget-purple-card h6,
.widget-primary-card.flat-card .h6,
.flat-card.widget-purple-card .h6 {
  color: #fff;
}
.widget-primary-card.flat-card .row-table:first-child, .flat-card.widget-purple-card .row-table:first-child {
  border-bottom: none;
}
.widget-primary-card.flat-card .row-table > [class*=col-], .flat-card.widget-purple-card .row-table > [class*=col-] {
  border-bottom: none;
  display: inline-block;
  vertical-align: middle;
}
.widget-primary-card.flat-card .row-table > [class*=col-]:first-child, .flat-card.widget-purple-card .row-table > [class*=col-]:first-child {
  background-color: #1c7cd2;
  text-align: center;
}
.widget-primary-card.flat-card .row-table > [class*=col-] .row, .flat-card.widget-purple-card .row-table > [class*=col-] .row {
  display: flex;
  align-items: center;
}

.widget-purple-card.flat-card {
  background-color: #00C853;
}
.widget-purple-card.flat-card .row-table:first-child {
  border-bottom: none;
}
.widget-purple-card.flat-card .row-table > [class*=col-] {
  border-bottom: none;
}
.widget-purple-card.flat-card .row-table > [class*=col-]:first-child {
  background-color: #00af48;
}

.balance-card .progress {
  width: 100%;
  height: 8px;
  background-color: #eceff1;
  border-radius: 30px;
}
.balance-card .progress .progress-bar {
  border-radius: 30px;
}

.card-progress .progress {
  height: 8px;
  width: 100%;
  position: relative;
  top: 8px;
  left: 0;
  border-radius: 0;
  overflow: inherit;
}

.total-request-card .progress {
  width: 85%;
  float: left;
  height: 5px;
  text-align: center;
  margin: 0 auto;
  background-color: #eceff1;
  border-radius: 30px;
}
.total-request-card .progress .progress-bar {
  border-radius: 30px;
}
.total-request-card hr {
  width: 50px;
  border-top: 1px solid #eeeeee;
  margin-top: 20px;
  margin-bottom: 20px;
}
.total-request-card i {
  position: relative;
  top: 5px;
}

.table-card .card-body,
.table-body.card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.table-card .card-body .table > thead > tr > th,
.table-body.card-body .table > thead > tr > th {
  border-top: 0;
}
.table-card .card-body .table tr td:first-child,
.table-card .card-body .table tr th:first-child,
.table-body.card-body .table tr td:first-child,
.table-body.card-body .table tr th:first-child {
  padding-left: 25px;
}
.table-card .card-body .table tr td:last-child,
.table-card .card-body .table tr th:last-child,
.table-body.card-body .table tr td:last-child,
.table-body.card-body .table tr th:last-child {
  padding-right: 25px;
}
.table-card .card-body .table.without-header tr:first-child td,
.table-body.card-body .table.without-header tr:first-child td {
  border-top: none;
}

.widget-statstic-card {
  position: relative;
  overflow: hidden;
}
.widget-statstic-card .st-icon {
  color: #fff;
  padding: 40px 40px 20px 20px;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  right: -30px;
  box-shadow: none;
}
.widget-statstic-card .st-icon > svg {
  transition: all 0.3s ease-in-out;
  width: 23px;
  height: 23px;
}
.widget-statstic-card h2, .widget-statstic-card .h2 {
  font-weight: 600;
  display: inline-block;
}
.widget-statstic-card span {
  border-radius: 30px;
  padding: 5px 20px;
  color: #fff;
  font-weight: 600;
}
.widget-statstic-card:hover .st-icon > svg {
  width: 50px;
  height: 50px;
}

.statustic-card .card-body {
  position: relative;
}
.statustic-card .card-body .progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 5px;
  overflow: visible;
}
.statustic-card .card-body .progress .progress-bar {
  position: relative;
  overflow: visible;
  box-shadow: none;
}
.statustic-card .card-body .progress .progress-bar:before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  background: inherit;
}
.statustic-card .card-body .progress .progress-bar.bg-primary:before {
  animation: blue-blink-bar 0.5s linear infinite;
}
.statustic-card .card-body .progress .progress-bar.bg-success:before {
  animation: green-blink-bar 0.5s linear infinite;
}
.statustic-card .card-body .progress .progress-bar.bg-danger:before {
  animation: red-blink-bar 0.5s linear infinite;
}
.statustic-card .card-body .progress .progress-bar.bg-warning:before {
  animation: yellow-blink-bar 0.5s linear infinite;
}

@keyframes blue-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(38, 137, 226, 0.1);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(38, 137, 226, 0.3);
  }
}
@keyframes green-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 200, 83, 0.1);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(0, 200, 83, 0.3);
  }
}
@keyframes red-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.1);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(244, 67, 54, 0.3);
  }
}
@keyframes yellow-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.1);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(255, 193, 7, 0.3);
  }
}
.social-card {
  color: #fff;
  overflow: hidden;
}
.social-card .social-icon {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 22px 23px;
}
.social-card .download-icon {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  height: 125px;
  width: 125px;
  right: -125px;
  padding: 47px 22px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.social-card .download-icon > svg {
  width: 35px;
  height: 35px;
}
.social-card:hover .download-icon {
  right: -60px;
}

.app-design .btn {
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}
.app-design .team-section img {
  width: 35px;
  border-radius: 5px;
}
.app-design .progress-box p {
  margin-bottom: 0;
}
.app-design .progress-box .progress {
  width: calc(100% - 80px);
  height: 8px;
  text-align: center;
  margin: 0 auto;
  background-color: #e5e5e5;
  border-radius: 30px;
  position: relative;
  overflow: inherit;
}
.app-design .progress-box .progress .progress-bar {
  border-radius: 30px;
}
.app-design .progress-box .progress .progress-bar label {
  position: absolute;
  top: -24px;
  right: 0;
  font-weight: 600;
  font-size: 13px;
}

.user-card-full {
  overflow: hidden;
}
.user-card-full .user-profile-side {
  border-radius: 5px 0 0 5px;
}
.user-card-full .social-link li {
  display: inline-block;
}
.user-card-full .social-link li a {
  color: #212529;
  margin: 0 10px 0 0;
  transition: all 0.3s ease-in-out;
}
.user-card-full .social-link li a svg {
  width: 20px;
  height: 20px;
}

.img-radius {
  border-radius: 50%;
}

.quater-card .progress {
  height: 4px;
}

.statustic-progress-card .progress {
  height: 5px;
}

.trafic-card .progress {
  height: 7px;
}
.trafic-card .progress.blue {
  background-color: rgba(38, 137, 226, 0.5);
}
.trafic-card .progress.green {
  background-color: rgba(0, 200, 83, 0.5);
}
.trafic-card .progress.red {
  background-color: rgba(244, 67, 54, 0.5);
}
.trafic-card .progress.purple {
  background-color: rgba(62, 201, 214, 0.5);
}
.trafic-card .progress.yellow {
  background-color: rgba(255, 193, 7, 0.5);
}

.total-card {
  overflow: hidden;
}
.total-card .text-left h4, .total-card .text-left .h4 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 5px;
}
.total-card .text-left p {
  color: #fff;
  font-size: 15px;
}

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}
.table-card .row-table svg {
  margin: 0 20px;
}
.table-card .row-table > [class*=col-] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}
.table-card .row-table > [class*=col-] .row {
  display: flex;
  align-items: center;
}

.rides-bar .rides-icon {
  background: #2689E2;
  border-radius: 50px;
  padding: 15px;
}

.visitor .img-female {
  position: absolute;
  left: 10px;
  bottom: 0;
}
.visitor .img-men {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.profit-bar .mon-icon {
  padding: 20px;
  border-radius: 50px;
  display: inline-block;
  float: right;
}
.profit-bar .mon-icon svg {
  width: 25px;
  height: 25px;
}

.user-card .label {
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 0;
  box-shadow: none;
}

.leads-progress .progress,
.progress-gender .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0;
}

.Active-visitor .progress {
  height: 7px;
}
.Active-visitor .card-active > div + div {
  border-left: 1px solid #eeeeee;
}
@media screen and (max-width: 767px) {
  .Active-visitor .card-active [class*=col-]:last-child {
    border-left: 0;
    margin-top: 20px;
  }
}

.card-Revenue .rev-icon {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.Invoice-bar .invoice-lable label {
  background: #fff;
  border-radius: 15px;
  padding: 4px 20px;
}
.Invoice-bar .inv-icon {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.Invoice-bar .progress {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0;
  height: 7px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.location-sale .card-icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .location-sale .card-icon {
    display: none;
  }
}

.card-Impression .imp-icon {
  width: 52px;
  height: 52px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.card-customer .hct-icon {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  box-shadow: none;
}
@media screen and (max-width: 375px) {
  .card-customer .hct-icon {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
}

.ticket-customer .tick-icon {
  position: absolute;
  opacity: 0.2;
  right: 32px;
  bottom: 30px;
}

.customer-visitor .vis-icon {
  opacity: 0.2;
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-social:hover i {
  font-size: 40px;
  transition: all 0.3s ease-in-out;
}
.card-social .progress {
  height: 6px;
}
.card-social .card-active > div + div {
  border-left: 1px solid #eeeeee;
}

.Online-Order .progress,
.statistial-visit .progress {
  height: 6px;
}

.affilate-offers .card-icon {
  opacity: 0.5;
}
.affilate-offers .label {
  border-radius: 15px;
  margin: 0;
  padding: 8px 18px;
}

.team-leader .slide li {
  width: 10px;
  height: 10px;
  background-color: #d4e7f9;
  margin: 0 6px;
  border-radius: 50px;
}
.team-leader .slide .carousel-indicators {
  margin-bottom: -10px;
  margin-top: 10px;
}
.team-leader .slide .carousel-indicators .active {
  background-color: #2689E2;
}

.earning-date .bd-example {
  padding: 0;
  margin: 0;
  border: 0;
}
.earning-date .bd-example .nav-pills {
  background: transparent;
  padding: 0;
  box-shadow: none;
}
.earning-date .bd-example .nav-pills .nav-link {
  min-width: auto;
  padding: 6px 20px;
  color: #fff;
}
.earning-date .bd-example .nav-pills .nav-link.active {
  position: relative;
  background: #fff;
  box-shadow: none;
  color: #2689E2;
}
.earning-date .bd-example .nav-pills .nav-link.active:after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  top: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 11px;
}
.earning-date .bd-example .tab-content {
  background: transparent;
  padding: 0;
  box-shadow: none;
}

.sale-view .sale-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-task svg {
  opacity: 0.5;
}
.project-task .label {
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px;
}
.project-task .progress {
  height: 6px;
}

.summary-box .card-icon {
  opacity: 0.4;
}

.card-event .label {
  border-radius: 15px;
  margin: 0;
  padding: 5px 16px;
}
.card-event svg {
  position: absolute;
  bottom: 20px;
  right: 25px;
}

.statistial-visit .label {
  padding: 4px 10px;
  border-radius: 40px;
}

.Design-sprint .design-image,
.dashboard-kit .design-image {
  padding: 0;
  margin: 30px 0 0;
  list-style: none;
}
.Design-sprint li,
.dashboard-kit li {
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}
.Design-sprint .btn,
.dashboard-kit .btn {
  border-radius: 50px;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.Design-sprint i,
.dashboard-kit i {
  opacity: 0.3;
}

.post-emoticon ul {
  padding: 0;
  margin: 25px 0 0;
  list-style: none;
}
.post-emoticon li {
  display: inline-block;
}

.ux-designer {
  position: relative;
  padding: 35px 30px;
}
.ux-designer .btn {
  border-radius: 50px;
  border: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 20px;
  top: -20px;
  padding: 0;
  margin: 0;
}

.visa-top .visa img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.loction-user .row {
  padding: 35px 30px;
}
.loction-user svg {
  opacity: 0.5;
}
.loction-user .loction-progress {
  padding: 35px 30px;
}
.loction-user .loction-progress .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0;
}

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0;
}
.task-list:after {
  content: "";
  position: absolute;
  background: #ecedef;
  height: 100%;
  width: 2px;
  top: 0;
  left: 30px;
  z-index: 1;
}
.task-list li {
  margin-bottom: 30px;
  padding-left: 55px;
  position: relative;
}
.task-list li:last-child {
  margin-bottom: 0;
}
.task-list li .task-icon {
  position: absolute;
  left: 22px;
  top: 13px;
  border-radius: 50%;
  padding: 2px;
  width: 17px;
  height: 17px;
  z-index: 2;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-main svg {
  opacity: 0.4;
}

.Application-list .table td,
.code-table .table td {
  vertical-align: middle;
}
.Application-list .table .label,
.code-table .table .label {
  padding: 4px 10px;
  border-radius: 15px;
}
.Application-list thead th,
.code-table thead th {
  border: 0;
}

.chat-sanders .card-header {
  background: linear-gradient(-135deg, #3f4d67 0%, #3f4d67 100%);
}
.chat-sanders .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 15px;
  position: relative;
}
.chat-sanders .scroll-wrapper {
  height: 305px;
}
.chat-sanders .received-chat h5, .chat-sanders .received-chat .h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .received-chat .msg {
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -12px;
  bottom: 18px;
  transform: rotate(270deg);
  border: 6px solid transparent;
  border-bottom-color: #fff;
}
.chat-sanders .send-chat h5, .chat-sanders .send-chat .h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .send-chat .msg {
  background: #2689E2;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .send-chat .msg:after {
  content: "";
  position: absolute;
  right: -11px;
  bottom: 18px;
  transform: rotate(450deg);
  border: 6px solid transparent;
  border-bottom-color: #2689E2;
}

.note-bar .friendlist-box {
  padding: 25px 35px;
  border-top: 1px solid #eeeeee;
}
.note-bar .friendlist-box:first-child {
  border: 0;
}
.note-bar .friendlist-box h6, .note-bar .friendlist-box .h6 {
  display: inline-block;
}
.note-bar .friendlist-box svg {
  opacity: 0.2;
  color: #212529;
}

.widget-content .widget-lorem .media h5, .widget-content .widget-lorem .media .h5 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}

.social-media .progress {
  background: transparent;
  border-radius: 0;
  justify-content: center;
}
.social-media .progress h5, .social-media .progress .h5 {
  position: relative;
  top: -2px;
}

.avtar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  width: 48px;
  height: 48px;
}
.avtar.avtar-xxs {
  width: 16px;
  height: 16px;
  font-size: 14px;
  border-radius: 5px;
}
.avtar.avtar-xs {
  width: 32px;
  height: 32px;
  font-size: 12px;
}
.avtar.avtar-s {
  width: 40px;
  height: 40px;
  font-size: 14px;
}
.avtar.avtar-l {
  width: 64px;
  height: 64px;
  font-size: 20px;
}
.avtar.avtar-xl {
  width: 80px;
  height: 80px;
  font-size: 24px;
}
.avtar.avtar-square {
  border-radius: 4px;
  border: 2px solid;
}
.avtar.avtar-icon {
  border: 2px solid #eeeeee;
}
.avtar.avtar-icon > img {
  width: 60%;
}

.badge.bg-light-primary {
  background: #d4e7f9;
  color: #2689E2;
  border-color: #d4e7f9;
}
.badge.bg-light-secondary {
  background: #e0cffc;
  color: #6610F2;
  border-color: #e0cffc;
}
.badge.bg-light-success {
  background: #ccf4dd;
  color: #00C853;
  border-color: #ccf4dd;
}
.badge.bg-light-info {
  background: #d8f4f7;
  color: #3EC9D6;
  border-color: #d8f4f7;
}
.badge.bg-light-warning {
  background: #fff3cd;
  color: #FFC107;
  border-color: #fff3cd;
}
.badge.bg-light-danger {
  background: #fdd9d7;
  color: #F44336;
  border-color: #fdd9d7;
}
.badge.bg-light-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}
.badge.bg-light-dark {
  background: #cfd1d7;
  color: #111936;
  border-color: #cfd1d7;
}

.btn {
  font-size: 14px;
}
.btn i {
  font-size: 18px;
}
.btn svg {
  width: 18px;
  height: 18px;
}
.btn[class*=btn-link-], .btn[class*=btn-light-] {
  box-shadow: none;
}
.btn[class*=btn-outline-]:not(:hover) {
  box-shadow: none;
}
.btn.btn-shadow {
  box-shadow: 0 6px 7px -1px rgba(80, 86, 175, 0.3);
}
.btn.btn-sm i, .btn-group-sm > .btn i {
  font-size: 14px;
}

.btn-light-primary {
  background: #d4e7f9;
  color: #2689E2;
  border-color: #d4e7f9;
}
.btn-light-primary .material-icons-two-tone {
  background-color: #2689E2;
}
.btn-light-primary:hover {
  background: #2689E2;
  color: #fff;
  border-color: #2689E2;
}
.btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-primary.focus, .btn-light-primary:focus {
  background: #2689E2;
  color: #fff;
  border-color: #2689E2;
}
.btn-light-primary.focus .material-icons-two-tone, .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-primary:not(:disabled):not(.disabled).active, .btn-light-primary:not(:disabled):not(.disabled):active, .show > .btn-light-primary.dropdown-toggle {
  background: #2689E2;
  color: #fff;
  border-color: #2689E2;
}
.btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-primary,
.btn-check:checked + .btn-light-primary {
  background: #2689E2;
  color: #fff;
  border-color: #2689E2;
}
.btn-check:active + .btn-light-primary .material-icons-two-tone,
.btn-check:checked + .btn-light-primary .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-primary {
  background: transparent;
  color: #2689E2;
  border-color: transparent;
}
.btn-link-primary .material-icons-two-tone {
  background-color: #2689E2;
}
.btn-link-primary:hover {
  background: #d4e7f9;
  color: #2689E2;
  border-color: #d4e7f9;
}
.btn-link-primary.focus, .btn-link-primary:focus {
  background: #d4e7f9;
  color: #2689E2;
  border-color: #d4e7f9;
}
.btn-link-primary:not(:disabled):not(.disabled).active, .btn-link-primary:not(:disabled):not(.disabled):active, .show > .btn-link-primary.dropdown-toggle {
  background: #d4e7f9;
  color: #2689E2;
  border-color: #d4e7f9;
}

.btn-check:active + .btn-link-primary,
.btn-check:checked + .btn-link-primary {
  background: #d4e7f9;
  color: #2689E2;
  border-color: #d4e7f9;
}

.btn-light-secondary {
  background: #e0cffc;
  color: #6610F2;
  border-color: #e0cffc;
}
.btn-light-secondary .material-icons-two-tone {
  background-color: #6610F2;
}
.btn-light-secondary:hover {
  background: #6610F2;
  color: #fff;
  border-color: #6610F2;
}
.btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-secondary.focus, .btn-light-secondary:focus {
  background: #6610F2;
  color: #fff;
  border-color: #6610F2;
}
.btn-light-secondary.focus .material-icons-two-tone, .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-secondary:not(:disabled):not(.disabled).active, .btn-light-secondary:not(:disabled):not(.disabled):active, .show > .btn-light-secondary.dropdown-toggle {
  background: #6610F2;
  color: #fff;
  border-color: #6610F2;
}
.btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-secondary,
.btn-check:checked + .btn-light-secondary {
  background: #6610F2;
  color: #fff;
  border-color: #6610F2;
}
.btn-check:active + .btn-light-secondary .material-icons-two-tone,
.btn-check:checked + .btn-light-secondary .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-secondary {
  background: transparent;
  color: #6610F2;
  border-color: transparent;
}
.btn-link-secondary .material-icons-two-tone {
  background-color: #6610F2;
}
.btn-link-secondary:hover {
  background: #e0cffc;
  color: #6610F2;
  border-color: #e0cffc;
}
.btn-link-secondary.focus, .btn-link-secondary:focus {
  background: #e0cffc;
  color: #6610F2;
  border-color: #e0cffc;
}
.btn-link-secondary:not(:disabled):not(.disabled).active, .btn-link-secondary:not(:disabled):not(.disabled):active, .show > .btn-link-secondary.dropdown-toggle {
  background: #e0cffc;
  color: #6610F2;
  border-color: #e0cffc;
}

.btn-check:active + .btn-link-secondary,
.btn-check:checked + .btn-link-secondary {
  background: #e0cffc;
  color: #6610F2;
  border-color: #e0cffc;
}

.btn-light-success {
  background: #ccf4dd;
  color: #00C853;
  border-color: #ccf4dd;
}
.btn-light-success .material-icons-two-tone {
  background-color: #00C853;
}
.btn-light-success:hover {
  background: #00C853;
  color: #fff;
  border-color: #00C853;
}
.btn-light-success:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-success.focus, .btn-light-success:focus {
  background: #00C853;
  color: #fff;
  border-color: #00C853;
}
.btn-light-success.focus .material-icons-two-tone, .btn-light-success:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-success:not(:disabled):not(.disabled).active, .btn-light-success:not(:disabled):not(.disabled):active, .show > .btn-light-success.dropdown-toggle {
  background: #00C853;
  color: #fff;
  border-color: #00C853;
}
.btn-light-success:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-success:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-success.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-success,
.btn-check:checked + .btn-light-success {
  background: #00C853;
  color: #fff;
  border-color: #00C853;
}
.btn-check:active + .btn-light-success .material-icons-two-tone,
.btn-check:checked + .btn-light-success .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-success {
  background: transparent;
  color: #00C853;
  border-color: transparent;
}
.btn-link-success .material-icons-two-tone {
  background-color: #00C853;
}
.btn-link-success:hover {
  background: #ccf4dd;
  color: #00C853;
  border-color: #ccf4dd;
}
.btn-link-success.focus, .btn-link-success:focus {
  background: #ccf4dd;
  color: #00C853;
  border-color: #ccf4dd;
}
.btn-link-success:not(:disabled):not(.disabled).active, .btn-link-success:not(:disabled):not(.disabled):active, .show > .btn-link-success.dropdown-toggle {
  background: #ccf4dd;
  color: #00C853;
  border-color: #ccf4dd;
}

.btn-check:active + .btn-link-success,
.btn-check:checked + .btn-link-success {
  background: #ccf4dd;
  color: #00C853;
  border-color: #ccf4dd;
}

.btn-light-info {
  background: #d8f4f7;
  color: #3EC9D6;
  border-color: #d8f4f7;
}
.btn-light-info .material-icons-two-tone {
  background-color: #3EC9D6;
}
.btn-light-info:hover {
  background: #3EC9D6;
  color: #fff;
  border-color: #3EC9D6;
}
.btn-light-info:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-info.focus, .btn-light-info:focus {
  background: #3EC9D6;
  color: #fff;
  border-color: #3EC9D6;
}
.btn-light-info.focus .material-icons-two-tone, .btn-light-info:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-info:not(:disabled):not(.disabled).active, .btn-light-info:not(:disabled):not(.disabled):active, .show > .btn-light-info.dropdown-toggle {
  background: #3EC9D6;
  color: #fff;
  border-color: #3EC9D6;
}
.btn-light-info:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-info:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-info.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-info,
.btn-check:checked + .btn-light-info {
  background: #3EC9D6;
  color: #fff;
  border-color: #3EC9D6;
}
.btn-check:active + .btn-light-info .material-icons-two-tone,
.btn-check:checked + .btn-light-info .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-info {
  background: transparent;
  color: #3EC9D6;
  border-color: transparent;
}
.btn-link-info .material-icons-two-tone {
  background-color: #3EC9D6;
}
.btn-link-info:hover {
  background: #d8f4f7;
  color: #3EC9D6;
  border-color: #d8f4f7;
}
.btn-link-info.focus, .btn-link-info:focus {
  background: #d8f4f7;
  color: #3EC9D6;
  border-color: #d8f4f7;
}
.btn-link-info:not(:disabled):not(.disabled).active, .btn-link-info:not(:disabled):not(.disabled):active, .show > .btn-link-info.dropdown-toggle {
  background: #d8f4f7;
  color: #3EC9D6;
  border-color: #d8f4f7;
}

.btn-check:active + .btn-link-info,
.btn-check:checked + .btn-link-info {
  background: #d8f4f7;
  color: #3EC9D6;
  border-color: #d8f4f7;
}

.btn-light-warning {
  background: #fff3cd;
  color: #FFC107;
  border-color: #fff3cd;
}
.btn-light-warning .material-icons-two-tone {
  background-color: #FFC107;
}
.btn-light-warning:hover {
  background: #FFC107;
  color: #fff;
  border-color: #FFC107;
}
.btn-light-warning:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-warning.focus, .btn-light-warning:focus {
  background: #FFC107;
  color: #fff;
  border-color: #FFC107;
}
.btn-light-warning.focus .material-icons-two-tone, .btn-light-warning:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-warning:not(:disabled):not(.disabled).active, .btn-light-warning:not(:disabled):not(.disabled):active, .show > .btn-light-warning.dropdown-toggle {
  background: #FFC107;
  color: #fff;
  border-color: #FFC107;
}
.btn-light-warning:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-warning:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-warning.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-warning,
.btn-check:checked + .btn-light-warning {
  background: #FFC107;
  color: #fff;
  border-color: #FFC107;
}
.btn-check:active + .btn-light-warning .material-icons-two-tone,
.btn-check:checked + .btn-light-warning .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-warning {
  background: transparent;
  color: #FFC107;
  border-color: transparent;
}
.btn-link-warning .material-icons-two-tone {
  background-color: #FFC107;
}
.btn-link-warning:hover {
  background: #fff3cd;
  color: #FFC107;
  border-color: #fff3cd;
}
.btn-link-warning.focus, .btn-link-warning:focus {
  background: #fff3cd;
  color: #FFC107;
  border-color: #fff3cd;
}
.btn-link-warning:not(:disabled):not(.disabled).active, .btn-link-warning:not(:disabled):not(.disabled):active, .show > .btn-link-warning.dropdown-toggle {
  background: #fff3cd;
  color: #FFC107;
  border-color: #fff3cd;
}

.btn-check:active + .btn-link-warning,
.btn-check:checked + .btn-link-warning {
  background: #fff3cd;
  color: #FFC107;
  border-color: #fff3cd;
}

.btn-light-danger {
  background: #fdd9d7;
  color: #F44336;
  border-color: #fdd9d7;
}
.btn-light-danger .material-icons-two-tone {
  background-color: #F44336;
}
.btn-light-danger:hover {
  background: #F44336;
  color: #fff;
  border-color: #F44336;
}
.btn-light-danger:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-danger.focus, .btn-light-danger:focus {
  background: #F44336;
  color: #fff;
  border-color: #F44336;
}
.btn-light-danger.focus .material-icons-two-tone, .btn-light-danger:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-danger:not(:disabled):not(.disabled).active, .btn-light-danger:not(:disabled):not(.disabled):active, .show > .btn-light-danger.dropdown-toggle {
  background: #F44336;
  color: #fff;
  border-color: #F44336;
}
.btn-light-danger:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-danger:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-danger.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-danger,
.btn-check:checked + .btn-light-danger {
  background: #F44336;
  color: #fff;
  border-color: #F44336;
}
.btn-check:active + .btn-light-danger .material-icons-two-tone,
.btn-check:checked + .btn-light-danger .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-danger {
  background: transparent;
  color: #F44336;
  border-color: transparent;
}
.btn-link-danger .material-icons-two-tone {
  background-color: #F44336;
}
.btn-link-danger:hover {
  background: #fdd9d7;
  color: #F44336;
  border-color: #fdd9d7;
}
.btn-link-danger.focus, .btn-link-danger:focus {
  background: #fdd9d7;
  color: #F44336;
  border-color: #fdd9d7;
}
.btn-link-danger:not(:disabled):not(.disabled).active, .btn-link-danger:not(:disabled):not(.disabled):active, .show > .btn-link-danger.dropdown-toggle {
  background: #fdd9d7;
  color: #F44336;
  border-color: #fdd9d7;
}

.btn-check:active + .btn-link-danger,
.btn-check:checked + .btn-link-danger {
  background: #fdd9d7;
  color: #F44336;
  border-color: #fdd9d7;
}

.btn-light-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}
.btn-light-light .material-icons-two-tone {
  background-color: #f8f9fa;
}
.btn-light-light:hover {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-light.focus, .btn-light-light:focus {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light.focus .material-icons-two-tone, .btn-light-light:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-light:not(:disabled):not(.disabled).active, .btn-light-light:not(:disabled):not(.disabled):active, .show > .btn-light-light.dropdown-toggle {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-light-light:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-light:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-light.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-light,
.btn-check:checked + .btn-light-light {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa;
}
.btn-check:active + .btn-light-light .material-icons-two-tone,
.btn-check:checked + .btn-light-light .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-light {
  background: transparent;
  color: #f8f9fa;
  border-color: transparent;
}
.btn-link-light .material-icons-two-tone {
  background-color: #f8f9fa;
}
.btn-link-light:hover {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}
.btn-link-light.focus, .btn-link-light:focus {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}
.btn-link-light:not(:disabled):not(.disabled).active, .btn-link-light:not(:disabled):not(.disabled):active, .show > .btn-link-light.dropdown-toggle {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}

.btn-check:active + .btn-link-light,
.btn-check:checked + .btn-link-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe;
}

.btn-light-dark {
  background: #cfd1d7;
  color: #111936;
  border-color: #cfd1d7;
}
.btn-light-dark .material-icons-two-tone {
  background-color: #111936;
}
.btn-light-dark:hover {
  background: #111936;
  color: #fff;
  border-color: #111936;
}
.btn-light-dark:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dark.focus, .btn-light-dark:focus {
  background: #111936;
  color: #fff;
  border-color: #111936;
}
.btn-light-dark.focus .material-icons-two-tone, .btn-light-dark:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dark:not(:disabled):not(.disabled).active, .btn-light-dark:not(:disabled):not(.disabled):active, .show > .btn-light-dark.dropdown-toggle {
  background: #111936;
  color: #fff;
  border-color: #111936;
}
.btn-light-dark:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-dark:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-dark.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-check:active + .btn-light-dark,
.btn-check:checked + .btn-light-dark {
  background: #111936;
  color: #fff;
  border-color: #111936;
}
.btn-check:active + .btn-light-dark .material-icons-two-tone,
.btn-check:checked + .btn-light-dark .material-icons-two-tone {
  background-color: #fff;
}

.btn-link-dark {
  background: transparent;
  color: #111936;
  border-color: transparent;
}
.btn-link-dark .material-icons-two-tone {
  background-color: #111936;
}
.btn-link-dark:hover {
  background: #cfd1d7;
  color: #111936;
  border-color: #cfd1d7;
}
.btn-link-dark.focus, .btn-link-dark:focus {
  background: #cfd1d7;
  color: #111936;
  border-color: #cfd1d7;
}
.btn-link-dark:not(:disabled):not(.disabled).active, .btn-link-dark:not(:disabled):not(.disabled):active, .show > .btn-link-dark.dropdown-toggle {
  background: #cfd1d7;
  color: #111936;
  border-color: #cfd1d7;
}

.btn-check:active + .btn-link-dark,
.btn-check:checked + .btn-link-dark {
  background: #cfd1d7;
  color: #111936;
  border-color: #cfd1d7;
}

.btn-light-facebook {
  background: #d9e1f0;
  color: #4267b2;
  border-color: #d9e1f0;
}
.btn-light-facebook .material-icons-two-tone {
  background-color: #4267b2;
}
.btn-light-facebook:hover {
  background-color: #4267b2;
  background: #4267b2;
  color: #fff;
  border-color: #4267b2;
}
.btn-light-facebook:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-facebook.focus, .btn-light-facebook:focus {
  background-color: #4267b2;
  background: #4267b2;
  color: #fff;
  border-color: #4267b2;
}
.btn-light-facebook.focus .material-icons-two-tone, .btn-light-facebook:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-facebook:not(:disabled):not(.disabled).active, .btn-light-facebook:not(:disabled):not(.disabled):active, .show > .btn-light-facebook.dropdown-toggle {
  background-color: #4267b2;
  background: #4267b2;
  color: #fff;
  border-color: #4267b2;
}
.btn-light-facebook:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-facebook:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-facebook.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-twitter {
  background: #d9f2fe;
  color: #42c0fb;
  border-color: #d9f2fe;
}
.btn-light-twitter .material-icons-two-tone {
  background-color: #42c0fb;
}
.btn-light-twitter:hover {
  background-color: #42c0fb;
  background: #42c0fb;
  color: #fff;
  border-color: #42c0fb;
}
.btn-light-twitter:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-twitter.focus, .btn-light-twitter:focus {
  background-color: #42c0fb;
  background: #42c0fb;
  color: #fff;
  border-color: #42c0fb;
}
.btn-light-twitter.focus .material-icons-two-tone, .btn-light-twitter:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-twitter:not(:disabled):not(.disabled).active, .btn-light-twitter:not(:disabled):not(.disabled):active, .show > .btn-light-twitter.dropdown-toggle {
  background-color: #42c0fb;
  background: #42c0fb;
  color: #fff;
  border-color: #42c0fb;
}
.btn-light-twitter:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-twitter:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-twitter.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-dribbble {
  background: #fbdbe7;
  color: #ec4a89;
  border-color: #fbdbe7;
}
.btn-light-dribbble .material-icons-two-tone {
  background-color: #ec4a89;
}
.btn-light-dribbble:hover {
  background-color: #ec4a89;
  background: #ec4a89;
  color: #fff;
  border-color: #ec4a89;
}
.btn-light-dribbble:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dribbble.focus, .btn-light-dribbble:focus {
  background-color: #ec4a89;
  background: #ec4a89;
  color: #fff;
  border-color: #ec4a89;
}
.btn-light-dribbble.focus .material-icons-two-tone, .btn-light-dribbble:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dribbble:not(:disabled):not(.disabled).active, .btn-light-dribbble:not(:disabled):not(.disabled):active, .show > .btn-light-dribbble.dropdown-toggle {
  background-color: #ec4a89;
  background: #ec4a89;
  color: #fff;
  border-color: #ec4a89;
}
.btn-light-dribbble:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-dribbble:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-dribbble.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-pinterest {
  background: #f2d3d6;
  color: #bf2131;
  border-color: #f2d3d6;
}
.btn-light-pinterest .material-icons-two-tone {
  background-color: #bf2131;
}
.btn-light-pinterest:hover {
  background-color: #bf2131;
  background: #bf2131;
  color: #fff;
  border-color: #bf2131;
}
.btn-light-pinterest:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-pinterest.focus, .btn-light-pinterest:focus {
  background-color: #bf2131;
  background: #bf2131;
  color: #fff;
  border-color: #bf2131;
}
.btn-light-pinterest.focus .material-icons-two-tone, .btn-light-pinterest:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-pinterest:not(:disabled):not(.disabled).active, .btn-light-pinterest:not(:disabled):not(.disabled):active, .show > .btn-light-pinterest.dropdown-toggle {
  background-color: #bf2131;
  background: #bf2131;
  color: #fff;
  border-color: #bf2131;
}
.btn-light-pinterest:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-pinterest:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-pinterest.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-youtube {
  background: #f9d4d2;
  color: #e0291d;
  border-color: #f9d4d2;
}
.btn-light-youtube .material-icons-two-tone {
  background-color: #e0291d;
}
.btn-light-youtube:hover {
  background-color: #e0291d;
  background: #e0291d;
  color: #fff;
  border-color: #e0291d;
}
.btn-light-youtube:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-youtube.focus, .btn-light-youtube:focus {
  background-color: #e0291d;
  background: #e0291d;
  color: #fff;
  border-color: #e0291d;
}
.btn-light-youtube.focus .material-icons-two-tone, .btn-light-youtube:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-youtube:not(:disabled):not(.disabled).active, .btn-light-youtube:not(:disabled):not(.disabled):active, .show > .btn-light-youtube.dropdown-toggle {
  background-color: #e0291d;
  background: #e0291d;
  color: #fff;
  border-color: #e0291d;
}
.btn-light-youtube:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-youtube:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-youtube.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-googleplus {
  background: #f4d8d5;
  color: #c73e2e;
  border-color: #f4d8d5;
}
.btn-light-googleplus .material-icons-two-tone {
  background-color: #c73e2e;
}
.btn-light-googleplus:hover {
  background-color: #c73e2e;
  background: #c73e2e;
  color: #fff;
  border-color: #c73e2e;
}
.btn-light-googleplus:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-googleplus.focus, .btn-light-googleplus:focus {
  background-color: #c73e2e;
  background: #c73e2e;
  color: #fff;
  border-color: #c73e2e;
}
.btn-light-googleplus.focus .material-icons-two-tone, .btn-light-googleplus:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-googleplus:not(:disabled):not(.disabled).active, .btn-light-googleplus:not(:disabled):not(.disabled):active, .show > .btn-light-googleplus.dropdown-toggle {
  background-color: #c73e2e;
  background: #c73e2e;
  color: #fff;
  border-color: #c73e2e;
}
.btn-light-googleplus:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-googleplus:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-googleplus.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-instagram {
  background: #eee5e0;
  color: #aa7c62;
  border-color: #eee5e0;
}
.btn-light-instagram .material-icons-two-tone {
  background-color: #aa7c62;
}
.btn-light-instagram:hover {
  background-color: #aa7c62;
  background: #aa7c62;
  color: #fff;
  border-color: #aa7c62;
}
.btn-light-instagram:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-instagram.focus, .btn-light-instagram:focus {
  background-color: #aa7c62;
  background: #aa7c62;
  color: #fff;
  border-color: #aa7c62;
}
.btn-light-instagram.focus .material-icons-two-tone, .btn-light-instagram:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-instagram:not(:disabled):not(.disabled).active, .btn-light-instagram:not(:disabled):not(.disabled):active, .show > .btn-light-instagram.dropdown-toggle {
  background-color: #aa7c62;
  background: #aa7c62;
  color: #fff;
  border-color: #aa7c62;
}
.btn-light-instagram:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-instagram:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-instagram.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-viber {
  background: #e5dceb;
  color: #7b519d;
  border-color: #e5dceb;
}
.btn-light-viber .material-icons-two-tone {
  background-color: #7b519d;
}
.btn-light-viber:hover {
  background-color: #7b519d;
  background: #7b519d;
  color: #fff;
  border-color: #7b519d;
}
.btn-light-viber:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-viber.focus, .btn-light-viber:focus {
  background-color: #7b519d;
  background: #7b519d;
  color: #fff;
  border-color: #7b519d;
}
.btn-light-viber.focus .material-icons-two-tone, .btn-light-viber:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-viber:not(:disabled):not(.disabled).active, .btn-light-viber:not(:disabled):not(.disabled):active, .show > .btn-light-viber.dropdown-toggle {
  background-color: #7b519d;
  background: #7b519d;
  color: #fff;
  border-color: #7b519d;
}
.btn-light-viber:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-viber:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-viber.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-behance {
  background: #ccddff;
  color: #0057ff;
  border-color: #ccddff;
}
.btn-light-behance .material-icons-two-tone {
  background-color: #0057ff;
}
.btn-light-behance:hover {
  background-color: #0057ff;
  background: #0057ff;
  color: #fff;
  border-color: #0057ff;
}
.btn-light-behance:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-behance.focus, .btn-light-behance:focus {
  background-color: #0057ff;
  background: #0057ff;
  color: #fff;
  border-color: #0057ff;
}
.btn-light-behance.focus .material-icons-two-tone, .btn-light-behance:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-behance:not(:disabled):not(.disabled).active, .btn-light-behance:not(:disabled):not(.disabled):active, .show > .btn-light-behance.dropdown-toggle {
  background-color: #0057ff;
  background: #0057ff;
  color: #fff;
  border-color: #0057ff;
}
.btn-light-behance:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-behance:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-behance.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-dropbox {
  background: #d6e6ff;
  color: #3380ff;
  border-color: #d6e6ff;
}
.btn-light-dropbox .material-icons-two-tone {
  background-color: #3380ff;
}
.btn-light-dropbox:hover {
  background-color: #3380ff;
  background: #3380ff;
  color: #fff;
  border-color: #3380ff;
}
.btn-light-dropbox:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dropbox.focus, .btn-light-dropbox:focus {
  background-color: #3380ff;
  background: #3380ff;
  color: #fff;
  border-color: #3380ff;
}
.btn-light-dropbox.focus .material-icons-two-tone, .btn-light-dropbox:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-dropbox:not(:disabled):not(.disabled).active, .btn-light-dropbox:not(:disabled):not(.disabled):active, .show > .btn-light-dropbox.dropdown-toggle {
  background-color: #3380ff;
  background: #3380ff;
  color: #fff;
  border-color: #3380ff;
}
.btn-light-dropbox:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-dropbox:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-dropbox.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-linkedin {
  background: #cce4f0;
  color: #0077b5;
  border-color: #cce4f0;
}
.btn-light-linkedin .material-icons-two-tone {
  background-color: #0077b5;
}
.btn-light-linkedin:hover {
  background-color: #0077b5;
  background: #0077b5;
  color: #fff;
  border-color: #0077b5;
}
.btn-light-linkedin:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-linkedin.focus, .btn-light-linkedin:focus {
  background-color: #0077b5;
  background: #0077b5;
  color: #fff;
  border-color: #0077b5;
}
.btn-light-linkedin.focus .material-icons-two-tone, .btn-light-linkedin:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-linkedin:not(:disabled):not(.disabled).active, .btn-light-linkedin:not(:disabled):not(.disabled):active, .show > .btn-light-linkedin.dropdown-toggle {
  background-color: #0077b5;
  background: #0077b5;
  color: #fff;
  border-color: #0077b5;
}
.btn-light-linkedin:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-linkedin:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-linkedin.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.btn-light-amazon {
  background: #cccccc;
  color: #000000;
  border-color: #cccccc;
}
.btn-light-amazon .material-icons-two-tone {
  background-color: #000000;
}
.btn-light-amazon:hover {
  background-color: #000000;
  background: #000000;
  color: #fff;
  border-color: #000000;
}
.btn-light-amazon:hover .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-amazon.focus, .btn-light-amazon:focus {
  background-color: #000000;
  background: #000000;
  color: #fff;
  border-color: #000000;
}
.btn-light-amazon.focus .material-icons-two-tone, .btn-light-amazon:focus .material-icons-two-tone {
  background-color: #fff;
}
.btn-light-amazon:not(:disabled):not(.disabled).active, .btn-light-amazon:not(:disabled):not(.disabled):active, .show > .btn-light-amazon.dropdown-toggle {
  background-color: #000000;
  background: #000000;
  color: #fff;
  border-color: #000000;
}
.btn-light-amazon:not(:disabled):not(.disabled).active .material-icons-two-tone, .btn-light-amazon:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > .btn-light-amazon.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}

.dropdown-toggle.arrow-none:after {
  display: none;
}

.dropdown-menu {
  padding: 10px 0;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  border: none;
  border-radius: 2px;
}

.pc-header .dropdown-menu {
  animation: 0.4s ease-in-out 0s normal forwards 1 fadein;
}

@keyframes fadein {
  from {
    transform: translate3d(0, 8px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.dropdown .dropdown-item.active i.material-icons-two-tone, .dropdown .dropdown-item:active i.material-icons-two-tone, .dropdown .dropdown-item:focus i.material-icons-two-tone, .dropdown .dropdown-item:hover i.material-icons-two-tone {
  background-color: #2689E2;
}

.dropdown .dropdown-item {
  padding: 10px 25px;
}
.dropdown .dropdown-item i {
  font-size: 18px;
  margin-right: 10px;
}
.dropdown .dropdown-item i.material-icons-two-tone {
  vertical-align: bottom;
  font-size: 22px;
  background-color: #616161;
}
.dropdown .dropdown-item svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  fill: #f2f2f2;
}
.dropdown .dropdown-item .float-right svg {
  width: 14px;
  height: 14px;
}

.card {
  margin-bottom: 24px;
  transition: box-shadow 0.2s ease-in-out;
}
.card .card-header {
  border-bottom: 1px solid #eeeeee;
}
.card .card-header h5, .card .card-header .h5 {
  margin-bottom: 0;
  color: #212529;
  font-size: 1rem;
  font-weight: 500;
}
.card .card-header h5 + p, .card .card-header .h5 + p,
.card .card-header h5 + small,
.card .card-header .h5 + small,
.card .card-header h5 + .small,
.card .card-header .h5 + .small {
  margin-top: 10px;
}
.card .card-header h5 + p:last-child, .card .card-header .h5 + p:last-child,
.card .card-header h5 + small:last-child,
.card .card-header .h5 + small:last-child,
.card .card-header h5 + .small:last-child,
.card .card-header .h5 + .small:last-child {
  margin-bottom: 0;
}
.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  float: right;
  padding: 0;
  position: absolute;
}
@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right {
    display: none;
  }
}
.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}
.card .card-header .card-header-right .dropdown-menu li {
  cursor: pointer;
}
.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}
.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #888;
}
.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}
.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 8px;
}
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span {
  background-color: #888;
  height: 2px;
  border-radius: 5px;
}
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:after, .card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:before {
  border-radius: 5px;
  height: 2px;
  background-color: #888;
}
.card .card-header .card-header-right .nav-pills {
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.card .card-footer {
  transition: box-shadow 0.2s ease-in-out;
  border-top: 1px solid #eeeeee;
}
.card:hover .card-footer[class*=bg-] {
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .card {
    margin-bottom: 20px;
  }
  .card .card-header {
    padding: 20px;
  }
  .card .card-header h5, .card .card-header .h5 {
    font-size: 0.875rem;
  }
  .card .card-body {
    padding: 20px;
  }
}
.progress {
  overflow: visible;
}
.progress .progress-bar {
  border-radius: 8px;
}
.progress .progress-bar.bg-primary {
  box-shadow: 0 8px 8px -1px rgba(38, 137, 226, 0.3);
}
.progress .progress-bar.bg-secondary {
  box-shadow: 0 8px 8px -1px rgba(102, 16, 242, 0.3);
}
.progress .progress-bar.bg-success {
  box-shadow: 0 8px 8px -1px rgba(0, 200, 83, 0.3);
}
.progress .progress-bar.bg-info {
  box-shadow: 0 8px 8px -1px rgba(62, 201, 214, 0.3);
}
.progress .progress-bar.bg-warning {
  box-shadow: 0 8px 8px -1px rgba(255, 193, 7, 0.3);
}
.progress .progress-bar.bg-danger {
  box-shadow: 0 8px 8px -1px rgba(244, 67, 54, 0.3);
}
.progress .progress-bar.bg-light {
  box-shadow: 0 8px 8px -1px rgba(248, 249, 250, 0.3);
}
.progress .progress-bar.bg-dark {
  box-shadow: 0 8px 8px -1px rgba(17, 25, 54, 0.3);
}

.table.table-align-center td,
.table.table-align-center th {
  vertical-align: middle;
}
.table thead th {
  padding: 0.9rem 0.75rem;
}
.table td,
.table th {
  vertical-align: middle;
}
.table.table-borderless td,
.table.table-borderless th {
  border: none !important;
}

.table-hover tbody tr:hover {
  background-color: rgba(38, 137, 226, 0.03);
}

.tabs-border.nav-tabs .nav-item {
  margin-bottom: 0;
}
.tabs-border.nav-tabs .nav-link {
  border: none;
  background: no-repeat center bottom, center 100%;
  background-size: 0 100%, 100% 100%;
  transition: background 0.3s ease-out;
  background-image: linear-gradient(to top, theme-color("primary") 2px, rgba(255, 255, 255, 0) 2px);
}
.tabs-border.nav-tabs .nav-link.active {
  background-size: 100% 100%, 100% 100%;
}

.tabs-light.nav-pill + .tab-content {
  border-top: 1px solid #eeeeee;
}
.tabs-light.nav-pill .nav-item {
  margin-bottom: 0;
}
.tabs-light.nav-pill .nav-item .nav-link {
  color: #2689E2;
  background: #d4e7f9;
  border-radius: 4px;
  transition: background 0.3s ease-out;
}
.tabs-light.nav-pill .nav-item + .nav-item {
  margin-left: 10px;
}
.tabs-light.nav-pill .nav-link {
  border: none;
}
.tabs-light.nav-pill .nav-link.active {
  color: #fff;
  background: #2689E2;
}

.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  font-size: 13px;
  font-weight: 500;
}

.input-group-text svg {
  width: 18px;
  height: 18px;
}

.form-control-color-picker {
  height: 43px;
  padding: 0.5rem;
}

select.form-control {
  appearance: none;
  background: #ffffff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat right 0.75rem center/18px 25px;
}
select.form-control[data-multiselectsplitter-firstselect-selector], select.form-control[data-multiselectsplitter-secondselect-selector] {
  background: none;
}

.form-floating > label {
  top: 1px;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: #6c757d;
}
.form-floating > .form-control:focus ~ label {
  color: #2689E2;
}
.form-floating > input {
  color: #212529;
}

.bootstrap-select -select > .dropdown-toggle.btn-light,
.bootstrap-select -select > .dropdown-toggle.btn-secondary,
.bootstrap-select -select > .dropdown-toggle.btn-default {
  border-color: #ced4da !important;
  box-shadow: none;
  background: #ffffff !important;
  color: #212529;
}
.bootstrap-select -select > .dropdown-toggle.btn-light:focus,
.bootstrap-select -select > .dropdown-toggle.btn-secondary:focus,
.bootstrap-select -select > .dropdown-toggle.btn-default:focus {
  outline: none !important;
}

.form-check label {
  cursor: pointer;
}
.form-check label input {
  cursor: pointer;
}
.form-check .form-check-input.input-primary:checked {
  border-color: #2689E2;
  background-color: #2689E2;
}
.form-check .form-check-input.input-light-primary:checked {
  border-color: #d4e7f9;
  background-color: #d4e7f9;
}
.form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232689E2' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232689E2'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-primary:focus[type=checkbox], .form-check .form-check-input.input-primary:focus[type=radio], .form-check .form-check-input.input-light-primary:focus[type=checkbox], .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(38, 137, 226, 0.25);
}
.form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232689E2'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-secondary:checked {
  border-color: #6610F2;
  background-color: #6610F2;
}
.form-check .form-check-input.input-light-secondary:checked {
  border-color: #e0cffc;
  background-color: #e0cffc;
}
.form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236610F2' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236610F2'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-secondary:focus[type=checkbox], .form-check .form-check-input.input-secondary:focus[type=radio], .form-check .form-check-input.input-light-secondary:focus[type=checkbox], .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
}
.form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236610F2'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-success:checked {
  border-color: #00C853;
  background-color: #00C853;
}
.form-check .form-check-input.input-light-success:checked {
  border-color: #ccf4dd;
  background-color: #ccf4dd;
}
.form-check .form-check-input.input-light-success:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2300C853' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-success:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2300C853'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-success:focus[type=checkbox], .form-check .form-check-input.input-success:focus[type=radio], .form-check .form-check-input.input-light-success:focus[type=checkbox], .form-check .form-check-input.input-light-success:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}
.form-check.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300C853'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-info:checked {
  border-color: #3EC9D6;
  background-color: #3EC9D6;
}
.form-check .form-check-input.input-light-info:checked {
  border-color: #d8f4f7;
  background-color: #d8f4f7;
}
.form-check .form-check-input.input-light-info:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233EC9D6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-info:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233EC9D6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-info:focus[type=checkbox], .form-check .form-check-input.input-info:focus[type=radio], .form-check .form-check-input.input-light-info:focus[type=checkbox], .form-check .form-check-input.input-light-info:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.25);
}
.form-check.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233EC9D6'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-warning:checked {
  border-color: #FFC107;
  background-color: #FFC107;
}
.form-check .form-check-input.input-light-warning:checked {
  border-color: #fff3cd;
  background-color: #fff3cd;
}
.form-check .form-check-input.input-light-warning:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFC107' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-warning:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFC107'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-warning:focus[type=checkbox], .form-check .form-check-input.input-warning:focus[type=radio], .form-check .form-check-input.input-light-warning:focus[type=checkbox], .form-check .form-check-input.input-light-warning:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
.form-check.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFC107'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-danger:checked {
  border-color: #F44336;
  background-color: #F44336;
}
.form-check .form-check-input.input-light-danger:checked {
  border-color: #fdd9d7;
  background-color: #fdd9d7;
}
.form-check .form-check-input.input-light-danger:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23F44336' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-danger:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23F44336'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-danger:focus[type=checkbox], .form-check .form-check-input.input-danger:focus[type=radio], .form-check .form-check-input.input-light-danger:focus[type=checkbox], .form-check .form-check-input.input-light-danger:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.form-check.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23F44336'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-light:checked {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
}
.form-check .form-check-input.input-light-light:checked {
  border-color: #fefefe;
  background-color: #fefefe;
}
.form-check .form-check-input.input-light-light:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-light:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f8f9fa'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light:focus[type=checkbox], .form-check .form-check-input.input-light:focus[type=radio], .form-check .form-check-input.input-light-light:focus[type=checkbox], .form-check .form-check-input.input-light-light:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.25);
}
.form-check.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f8f9fa'/%3e%3c/svg%3e");
}

.form-check .form-check-input.input-dark:checked {
  border-color: #111936;
  background-color: #111936;
}
.form-check .form-check-input.input-light-dark:checked {
  border-color: #cfd1d7;
  background-color: #cfd1d7;
}
.form-check .form-check-input.input-light-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23111936' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-light-dark:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23111936'/%3e%3c/svg%3e");
}
.form-check .form-check-input.input-dark:focus[type=checkbox], .form-check .form-check-input.input-dark:focus[type=radio], .form-check .form-check-input.input-light-dark:focus[type=checkbox], .form-check .form-check-input.input-light-dark:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(17, 25, 54, 0.25);
}
.form-check.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23111936'/%3e%3c/svg%3e");
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-right: none;
}

.form-v1 .form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
.form-v1 .form-label-group > input,
.form-v1 .form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}
.form-v1 .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  color: #495057;
  pointer-events: none;
  cursor: text;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.form-v1 .form-label-group input::-webkit-input-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::-moz-placeholder {
  color: transparent;
}
.form-v1 .form-label-group input::placeholder {
  color: transparent;
}
.form-v1 .form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-v1 .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-v1 .form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}
.form-v1 .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.datepicker {
  width: 265px;
  padding: 10px;
}
.datepicker.dropdown-menu {
  padding: 10px;
}
.datepicker.datepicker-orient-top {
  margin-top: 8px;
}
.datepicker table {
  width: 100%;
}
.datepicker td,
.datepicker th {
  font-weight: regular;
  width: 35px;
  height: 35px;
}
.datepicker thead th {
  color: #212529;
}
.datepicker thead th.datepicker-switch, .datepicker thead th.next, .datepicker thead th.prev {
  font-weight: 500;
  color: #212529;
}
.datepicker thead th.datepicker-switch i, .datepicker thead th.next i, .datepicker thead th.prev i {
  font-size: 1.2rem;
  color: #212529;
}
.datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before, .datepicker thead th.prev i:before {
  line-height: 0;
  vertical-align: middle;
}
.datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover, .datepicker thead th.prev:hover {
  background: #eceff1 !important;
}
.datepicker thead th.dow {
  color: #212529;
  font-weight: 500;
}
.datepicker tbody tr > td.day {
  color: #212529;
}
.datepicker tbody tr > td.day:hover {
  background: #eceff1;
  color: #212529;
}
.datepicker tbody tr > td.day.old {
  color: #212529;
}
.datepicker tbody tr > td.day.new {
  color: #212529;
}
.datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover, .datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover {
  background: #2689E2;
  color: #fff;
}
.datepicker tbody tr > td.day.today {
  position: relative;
  background: #2689E2 !important;
  color: #fff !important;
}
.datepicker tbody tr > td.day.today:before {
  content: "";
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #ffffff;
  border-top-color: #212529;
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.datepicker tbody tr > td.day.range {
  background: #eceff1;
}
.datepicker tbody tr > td span.hour,
.datepicker tbody tr > td span.minute,
.datepicker tbody tr > td span.month,
.datepicker tbody tr > td span.year {
  color: #212529;
}
.datepicker tbody tr > td span.hour:hover,
.datepicker tbody tr > td span.minute:hover,
.datepicker tbody tr > td span.month:hover,
.datepicker tbody tr > td span.year:hover {
  background: #eceff1;
}
.datepicker tbody tr > td span.hour.active, .datepicker tbody tr > td span.hour.active.focused:hover, .datepicker tbody tr > td span.hour.active:hover, .datepicker tbody tr > td span.hour.focused, .datepicker tbody tr > td span.hour.focused:hover,
.datepicker tbody tr > td span.minute.active,
.datepicker tbody tr > td span.minute.active.focused:hover,
.datepicker tbody tr > td span.minute.active:hover,
.datepicker tbody tr > td span.minute.focused,
.datepicker tbody tr > td span.minute.focused:hover,
.datepicker tbody tr > td span.month.active,
.datepicker tbody tr > td span.month.active.focused:hover,
.datepicker tbody tr > td span.month.active:hover,
.datepicker tbody tr > td span.month.focused,
.datepicker tbody tr > td span.month.focused:hover,
.datepicker tbody tr > td span.year.active,
.datepicker tbody tr > td span.year.active.focused:hover,
.datepicker tbody tr > td span.year.active:hover,
.datepicker tbody tr > td span.year.focused,
.datepicker tbody tr > td span.year.focused:hover {
  background: #2689E2;
  color: #fff;
}
.datepicker tfoot tr > th.clear, .datepicker tfoot tr > th.today {
  font-weight: 500;
}
.datepicker tfoot tr > th.clear:hover, .datepicker tfoot tr > th.today:hover {
  background: #eceff1;
}

.bootstrap-timepicker-widget {
  left: auto;
  width: 175px;
}
.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  border: none;
  box-shadow: none;
}
.bootstrap-timepicker-widget table td > a {
  border: 0;
}
.bootstrap-timepicker-widget table td > a i {
  font-size: 1.1rem;
}

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  color: #212529;
}
.bootstrap-timepicker-widget table td > a:hover {
  background: #eceff1;
}

.tagify__tag {
  --tag-bg: #eceff1;
  --tag-hover: rgba(38, 137, 226, 0.3);
  --tag-remove-bg: rgba(244, 67, 54, 0.3);
  --tag-remove-btn-bg--hover: #F44336;
}
.tagify__tag.tagify__tag--primary {
  --tag-bg: rgba(38, 137, 226, 0.2);
}
.tagify__tag.tagify__tag--primary div .tagify__tag-text {
  color: #2689E2;
}
.tagify__tag.tagify__tag--secondary {
  --tag-bg: rgba(102, 16, 242, 0.2);
}
.tagify__tag.tagify__tag--secondary div .tagify__tag-text {
  color: #6610F2;
}
.tagify__tag.tagify__tag--success {
  --tag-bg: rgba(0, 200, 83, 0.2);
}
.tagify__tag.tagify__tag--success div .tagify__tag-text {
  color: #00C853;
}
.tagify__tag.tagify__tag--info {
  --tag-bg: rgba(62, 201, 214, 0.2);
}
.tagify__tag.tagify__tag--info div .tagify__tag-text {
  color: #3EC9D6;
}
.tagify__tag.tagify__tag--warning {
  --tag-bg: rgba(255, 193, 7, 0.2);
}
.tagify__tag.tagify__tag--warning div .tagify__tag-text {
  color: #FFC107;
}
.tagify__tag.tagify__tag--danger {
  --tag-bg: rgba(244, 67, 54, 0.2);
}
.tagify__tag.tagify__tag--danger div .tagify__tag-text {
  color: #F44336;
}
.tagify__tag.tagify__tag--light {
  --tag-bg: rgba(248, 249, 250, 0.2);
}
.tagify__tag.tagify__tag--light div .tagify__tag-text {
  color: #f8f9fa;
}
.tagify__tag.tagify__tag--dark {
  --tag-bg: rgba(17, 25, 54, 0.2);
}
.tagify__tag.tagify__tag--dark div .tagify__tag-text {
  color: #111936;
}

.bootstrap-switch {
  border-color: #eeeeee;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  background: #eceff1;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #fff;
  background: #2689E2;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-secondary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-secondary {
  color: #fff;
  background: #6610F2;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #fff;
  background: #00C853;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  color: #fff;
  background: #3EC9D6;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  color: #fff;
  background: #FFC107;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  color: #fff;
  background: #F44336;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light {
  color: #fff;
  background: #f8f9fa;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark {
  color: #fff;
  background: #111936;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn[class*=btn-]:not(.btn-light) {
  color: #fff;
}

.typeahead .twitter-typeahead {
  display: block !important;
}

.scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}

.tt-menu {
  padding: 10px 0;
  font-size: 0.875rem;
  background: #fff;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.tt-menu .tt-suggestion {
  padding: 4px 20px;
  border-radius: 2px;
}
.tt-menu .tt-suggestion.active, .tt-menu .tt-suggestion:active, .tt-menu .tt-suggestion:focus, .tt-menu .tt-suggestion:hover {
  background: rgba(38, 137, 226, 0.03);
  color: #2689E2;
}

.sticky-action {
  top: 80px;
  position: sticky;
  z-index: 1020;
  background: var(--bs-card-bg);
}

.dropzone {
  min-height: auto;
}

.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #eeeeee;
  border-radius: 8px;
}
.dropzone .dropzone-msg-title {
  color: #212529;
  margin: 0 0 5px;
  padding: 0;
  font-weight: 500;
  font-size: 1.2rem;
}
.dropzone .dropzone-msg-desc {
  color: #212529;
  font-weight: 400;
  font-size: 1rem;
}
.dropzone .dz-preview .dz-image {
  border-radius: 8px;
}
.dropzone.dropzone-primary {
  border-color: #2689E2;
}
.dropzone.dropzone-secondary {
  border-color: #6610F2;
}
.dropzone.dropzone-success {
  border-color: #00C853;
}
.dropzone.dropzone-info {
  border-color: #3EC9D6;
}
.dropzone.dropzone-warning {
  border-color: #FFC107;
}
.dropzone.dropzone-danger {
  border-color: #F44336;
}
.dropzone.dropzone-light {
  border-color: #f8f9fa;
}
.dropzone.dropzone-dark {
  border-color: #111936;
}

.dz-started .dropzone-msg {
  display: none;
}

.dropzone-multi {
  border: 0;
  padding: 0;
}
.dropzone-multi .dz-message {
  display: none;
}
.dropzone-multi .dropzone-panel .dropzone-remove-all,
.dropzone-multi .dropzone-panel .dropzone-upload {
  display: none;
}
.dropzone-multi .dropzone-item {
  background: #eceff1;
  border-radius: 8px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
}
.dropzone-multi .dropzone-item .dropzone-progress {
  width: 20%;
}
.dropzone-multi .dropzone-item .dropzone-progress .progress {
  height: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename {
  font-size: 0.9rem;
  font-weight: 500;
  color: #212529;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename b {
  font-size: 0.9rem;
  font-weight: 500;
  color: #212529;
}
.dropzone-multi .dropzone-item .dropzone-file .dropzone-error {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #F44336;
  text-overflow: ellipsis;
}
.dropzone-multi .dropzone-item .dropzone-toolbar {
  margin-left: 1rem;
  display: flex;
  flex-wrap: nowrap;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start i {
  font-size: 0.8rem;
  color: #212529;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start:hover i {
  color: #2689E2;
}
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
  transition: all 0.2s ease-in-out;
}

.uppy-Dashboard--modal {
  z-index: 1030;
}
.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  z-index: 1030;
}
.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1031;
}

.uppy-DragDrop--is-dragdrop-supported {
  border-color: #eceff1;
}

.pc-uppy-thumbnail-container .pc-uppy-thumbnail img {
  width: 100px;
}

.slider-selection {
  box-shadow: none;
}

.pc-toggle-noUiSlider {
  height: 50px;
}

.pc-toggle-noUiSlider.off .noUi-handle {
  border-color: #F44336;
  background: #F44336;
  box-shadow: none;
}

.note-editor.card .card-header {
  padding: 0 5px 5px;
  border-bottom-color: #a9a9a9;
}

#cke5-inline-demo .ck-content {
  margin-bottom: 1rem;
  padding: 2.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#cke5-inline-demo .ck-content h3, #cke5-inline-demo .ck-content .h3 {
  margin-top: 0;
}
#cke5-inline-demo header.ck-content {
  text-align: center;
}
#cke5-inline-demo header.ck-content h2:first-of-type, #cke5-inline-demo header.ck-content .h2:first-of-type {
  margin-top: 0;
}
#cke5-inline-demo header.ck-content h2 + h3, #cke5-inline-demo header.ck-content .h2 + h3, #cke5-inline-demo header.ck-content h2 + .h3, #cke5-inline-demo header.ck-content .h2 + .h3 {
  margin-top: 0;
  color: #212529;
  font-weight: 600;
}
#cke5-inline-demo .demo-row {
  width: 100%;
  display: flex;
}
#cke5-inline-demo .demo-row .demo-row__half {
  width: 50%;
}
#cke5-inline-demo .demo-row .demo-row__half:first-of-type {
  padding-right: 0.5rem;
}
#cke5-inline-demo .demo-row .demo-row__half:last-of-type {
  padding-left: 0.5rem;
}
#cke5-inline-demo .demo-row p {
  margin-bottom: 0;
}
#cke5-inline-demo .demo-row h3, #cke5-inline-demo .demo-row .h3 {
  margin: 0;
  font-weight: 600;
}

.switch-demo .custom-switch-v1 {
  margin-bottom: 4px;
}

.custom-switch-v1.form-switch {
  padding-left: 2.9em;
}
.custom-switch-v1.form-switch .form-check-input {
  height: 20px;
  width: 35px;
  margin-left: -2.9em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.custom-switch-v1.form-switch .form-check-input[class*=input-light-] {
  border: none;
}
.custom-switch-v1.form-switch .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.custom-switch-v1.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%232689E2'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%236610F2'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2300C853'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%233EC9D6'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23FFC107'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23F44336'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f8f9fa'/%3e%3c/svg%3e");
}
.custom-switch-v1.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23111936'/%3e%3c/svg%3e");
}
.custom-switch-v1 .custom-control-label::before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 0.8rem;
  top: 0;
  left: -2.55rem;
}
.custom-switch-v1 .custom-control-label::after {
  top: calc(0.15625rem - 2px);
  left: calc(-2.25rem - 4px);
  height: 19px;
  width: 19px;
  border-radius: 0.7rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.custom-switch-v1 .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.95rem);
}

.row[data-multiselectsplitter-wrapper-selector] select {
  margin-bottom: 8px;
}

.choices {
  position: relative;
  margin-bottom: 5px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #e9ecef;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ced4da;
  background-color: #ffffff;
  margin: 0;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*=select-one] .choices__button:focus, .choices[data-type*=select-one] .choices__button:hover {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0 0 0 2px #2689E2;
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*=select-one]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #212529 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*=select-one].is-open:after {
  border-color: transparent transparent #212529 transparent;
  margin-top: -7.5px;
}
.choices[data-type*=select-one][dir=rtl]:after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.35);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:focus, .choices[data-type*=select-multiple] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #ffffff;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner, .is-open .choices__inner {
  border-width: 1px;
  border-color: #2689E2;
  box-shadow: 0 0 0 0.2rem rgba(38, 137, 226, 0.25);
}
.is-open .choices__inner {
  border-radius: 6px 6px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 6px 6px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 6px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #2689E2;
  border: 1px solid #1c7cd2;
  color: #ffffff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #1c7cd2;
  border: 1px solid #196fbc;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #9facb9;
  border: 1px solid #8293a4;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.choices__list--dropdown.is-active {
  visibility: visible;
}
.is-open .choices__list--dropdown {
  border-color: #a2aeb9;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir=rtl] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: rgba(38, 137, 226, 0.1);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #ebeef0;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #ffffff;
  font-size: 14px;
  margin-bottom: 0px;
  border: 0;
  color: #212529;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

.typeahead {
  position: relative;
}
.typeahead > ul {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
  display: none;
  float: left;
  font-size: 14px;
  left: 0;
  list-style: none;
  margin: 2px 0 0;
  min-width: 170px;
  padding: 5px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}
.typeahead > ul > li > a {
  clear: both;
  color: #333;
  cursor: default;
  display: block;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 3px 20px;
  white-space: nowrap;
}
.typeahead > ul > li.active > a,
.typeahead > ul > li > a:hover {
  background-color: #3f9bfa;
  color: #ffffff;
  text-decoration: none;
}

.validate-me .error {
  border-color: #F44336;
}

.error-message {
  color: #F44336;
}

.flatpickr-input[readonly] {
  background: #ffffff;
}

.auth-main {
  position: relative;
}
.auth-main .auth-wrapper {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}
.auth-main .auth-wrapper .saprator {
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
}
.auth-main .auth-wrapper .saprator:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #eeeeee;
  z-index: 1;
}
.auth-main .auth-wrapper .saprator span {
  font-size: 0.875rem;
  padding: 20px 70px;
  background: #fff;
  z-index: 5;
  text-transform: uppercase;
  color: #212529;
  font-weight: 500;
  outline: 1px solid #eeeeee;
  outline-offset: -16px;
  border-radius: 10px;
}
.auth-main .auth-wrapper .auth-sidecontent {
  overflow: hidden;
}
.auth-main .auth-wrapper.v2 {
  display: flex;
  align-items: center;
}
.auth-main .auth-wrapper.v2 .logo {
  width: 100%;
  padding: 24px;
  position: absolute;
  top: 0;
  left: 0;
}
.auth-main .auth-wrapper.v2 .logo + .card {
  margin-top: 80px;
}
.auth-main .auth-wrapper.v2 .auth-form {
  flex-direction: column;
}
.auth-main .auth-wrapper.v2 .auth-sidecontent {
  width: 500px;
  align-self: stretch;
  display: flex;
  align-items: center;
  position: relative;
  background: #d4e7f9;
}
@media (max-width: 991.98px) {
  .auth-main .auth-wrapper.v2 .auth-sidecontent {
    display: none;
  }
}
.auth-main .auth-wrapper.v2 .auth-sidecontent > * {
  position: relative;
  z-index: 5;
}
.auth-main .auth-wrapper.v2 .auth-sidecontent::after {
  content: "";
  background-image: url("../images/authentication/auth2-login.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  opacity: 0.1;
}
.auth-main .auth-wrapper.v2 .auth-sidecontent .carousel {
  position: relative;
  top: 25px;
}
.auth-main .auth-wrapper.v1 {
  display: flex;
  align-items: center;
}
.auth-main .auth-wrapper.v1 .auth-form {
  background: #d4e7f9;
  min-height: 100vh;
  padding: 24px;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent {
  width: 500px;
  align-self: stretch;
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 991.98px) {
  .auth-main .auth-wrapper.v1 .auth-sidecontent {
    display: none;
  }
}
.auth-main .auth-wrapper.v1 .auth-sidecontent > * {
  position: relative;
  z-index: 5;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent::after {
  content: "";
  background-image: url("../images/authentication/auth2-login.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  opacity: 0.1;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content {
  position: relative;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .blue-card {
  position: absolute;
  animation: 15s ease-in-out 1s infinite normal none running wings;
  left: 10%;
  width: 330px;
  height: 270px;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .purple-card {
  position: relative;
  animation: 15s ease-in-out 0s infinite normal none running wings;
  left: 10%;
  top: 100px;
  height: 280px;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .signup-blue-card {
  position: relative;
  animation: 15s ease-in-out 1s infinite normal none running wings;
  width: 400px;
  top: 40px;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .signup-white-card {
  position: absolute;
  animation: 15s ease-in-out 0s infinite normal none running wings;
  left: 25%;
  top: 50%;
  max-width: 100%;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .multi-card {
  position: relative;
  animation: 15s ease-in-out 1s infinite normal none running wings;
  top: 100px;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .mail-card {
  position: relative;
  animation: 15s ease-in-out 1s infinite normal none running wings;
  top: 100px;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .reset-error-card {
  position: absolute;
  animation: 15s ease-in-out 1s infinite normal none running wings;
  height: 270px;
  top: 30px;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .animation-content .reset-purple-card {
  position: relative;
  animation: 15s ease-in-out 0s infinite normal none running wings;
  width: 360px;
  height: 400px;
  top: 140px;
  left: 15%;
}
.auth-main .auth-wrapper.v1 .auth-sidecontent .carousel {
  position: relative;
  top: 25px;
}
.auth-main .auth-wrapper.v3 {
  display: flex;
  align-items: center;
}
.auth-main .auth-wrapper.v3 .auth-form {
  background: #d4e7f9;
  min-height: 100vh;
  padding: 24px;
}
.auth-main .auth-wrapper .auth-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.auth-main .auth-wrapper .auth-form .card {
  width: 100%;
  max-width: 480px;
  box-shadow: none;
}
.auth-main .auth-wrapper .auth-form img {
  padding-right: 15px;
}
.auth-main .auth-wrapper .auth-form h5 span, .auth-main .auth-wrapper .auth-form .h5 span {
  text-decoration: underline;
}
.auth-main .auth-wrapper .auth-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.maintenance-block {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.error-card {
  box-shadow: none;
  text-align: center;
}
.error-card .error-image-block {
  position: relative;
  text-align: center;
  display: inline-flex;
}
.error-card .error-image-block .img-404 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.error-card .error-image-block .img-404.error-text {
  animation: 3s ease-in-out 0s infinite normal none running bounce;
}
.error-card .error-image-block .img-404.error-primary {
  animation: 15s ease-in-out 0s infinite normal none running wings;
}
.error-card .error-image-block .img-404.error-secondary {
  animation: 12s ease-in-out 0s infinite normal none running wings;
}

.construction-card {
  text-align: center;
}
.construction-card .construction-image-block {
  position: relative;
  display: inline-flex;
}
.construction-card .construction-image-block .img-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: 10s ease-in-out 0s infinite normal none running blink;
}
.construction-card .construction-image-block .img-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: 5s ease-in-out 0s infinite normal none running bounce;
}

.soon-card {
  text-align: center;
}
.soon-card .soon-image-block {
  position: relative;
  display: inline-block;
}
.soon-card .soon-image-block .img-soon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.soon-card .soon-image-block .img-soon.soon-box {
  animation: blink 5s ease-in-out infinite;
}
.soon-card .soon-image-block .img-soon.soon-secondary, .soon-card .soon-image-block .img-soon.soon-primary {
  animation: wings 5s ease-in-out infinite;
}
.soon-card .soon-image-block .img-soon.soon-secondary {
  animation-delay: 2s;
}

.commingsoon-wrapper {
  display: flex;
  align-items: center;
}
.commingsoon-wrapper .sideanimation-block {
  position: relative;
}
.commingsoon-wrapper .sideanimation-block .img-bg-ring {
  height: 100vh;
}
.commingsoon-wrapper .sideanimation-block .img-bg-logo {
  position: absolute;
  top: 40px;
  left: 40px;
}
.commingsoon-wrapper .sideanimation-block .img-comming {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-bg-1, .commingsoon-wrapper .sideanimation-block .img-comming.img-bg-2 {
  animation: blink 5s ease-in-out infinite;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-bg-2 {
  animation-delay: 1.5s;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-widget {
  animation: wings 5s ease-in-out infinite;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-widget.img-widget1 {
  animation-delay: 1s;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-widget.img-widget2 {
  animation-delay: 2s;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-widget.img-widget3 {
  animation-delay: 3s;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-widget.img-widget4 {
  animation-delay: 4s;
}
.commingsoon-wrapper .sideanimation-block .img-comming.img-widget.img-widget5 {
  animation-delay: 5s;
}
.commingsoon-wrapper .content-block {
  flex: 1 1 auto;
  width: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commingsoon-wrapper .content-block .commingsoon-block {
  max-width: 600px;
}
.commingsoon-wrapper .content-block .cs-footer-text {
  margin-top: 30px;
  text-align: end;
}
.commingsoon-wrapper .content-block .cs-footer-text .d-flex {
  justify-content: flex-end;
}
.commingsoon-wrapper .commingsoon-slider {
  position: relative;
}
.commingsoon-wrapper .commingsoon-slider::after {
  content: "";
  position: absolute;
  top: -24px;
  right: 0;
  border-radius: var(--bs-border-radius);
  width: calc(100% - 24px);
  height: 100%;
  background: rgba(var(--bs-primary-rgb), 0.1);
  z-index: 1;
}
.commingsoon-wrapper .commingsoon-slider .carousel-inner {
  position: relative;
  overflow: hidden;
  z-index: 9;
  border-radius: var(--bs-border-radius);
  margin-right: 24px;
  margin-top: 24px;
  width: calc(100% - 24px);
  box-shadow: 0px 0px 50px rgba(33, 150, 243, 0.2);
}
@media (max-width: 1199.98px) {
  .commingsoon-wrapper {
    flex-direction: column;
  }
  .commingsoon-wrapper .sideanimation-block {
    order: 2;
    width: 100%;
    position: static;
    margin-top: -160px;
  }
  .commingsoon-wrapper .sideanimation-block .bottom-img {
    display: none;
  }
  .commingsoon-wrapper .content-block {
    order: 1;
    width: 100%;
    margin-top: 100px;
  }
  .commingsoon-wrapper .content-block .cs-footer-text {
    margin-top: 50px;
    text-align: center;
  }
  .commingsoon-wrapper .content-block .cs-footer-text .d-flex {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .commingsoon-wrapper .sideanimation-block .img-comm-ring-small {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .timer-block .avtar.avtar-xl {
    width: 45px;
    height: 45px;
    font-size: 16px;
  }
}
@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes blink {
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes bounce {
  0%, 20%, 53%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}
/**  =====================
      Icon layouts css start
==========================  **/
.i-main .i-block {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 1px solid #eeeeee;
  position: relative;
  cursor: pointer;
}
.i-main .i-block i {
  font-size: 30px;
}
.i-main .i-block label {
  margin-bottom: 0;
  display: none;
}
.i-main .i-block span.ic-badge {
  position: absolute;
  bottom: 0;
  right: 0;
}

/**====== Icon layouts css end ======**/