/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */

// main framework
@import "settings/preset-variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
$pc-body-bg: map-get($preset-colors, "preset-1", "body");
$pc-blue: map-get($preset-colors, "preset-1", "primary");
$pc-secondary: map-get($preset-colors, "preset-1", "secondary");
@import "settings/custom-variables";
@import "settings/theme-variables";

@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/offcanvas";
@import "node_modules/bootstrap/scss/placeholders";
@import "node_modules/bootstrap/scss/helpers";

@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/utilities/api";

// main framework
@import "themes/generic";
@import "themes/general";

// Layouts
@import "themes/layouts/pc-sidebar";
@import "themes/layouts/pc-header";
@import "themes/layouts/pc-footer";
@import "themes/layouts/pc-common";

// widget
@import "themes/components/widget";

// Componants
@import "themes/components/avtar";
@import "themes/components/badge";
@import "themes/components/button";
@import "themes/components/dropdown";
@import "themes/components/card";
@import "themes/components/progress";
@import "themes/components/table";
@import "themes/components/tabs";
@import "themes/components/form";

// pages
@import "themes/pages/authentication";
@import "themes/pages/icon-lauouts";
