// ============================
//     header css start
// ============================

.pc-footer {
  position: relative;
  z-index: 999;
  margin-left: $sidebar-width;
  margin-right: 20px;
  margin-top: $header-height;
  padding: 15px 0;
  .footer-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer-link {
    .list-inline-item:not(:last-child) {
      margin-right: 0.9rem;
    }
  }
  @media (max-width: 1024px) {
    margin-left: 20px;
  }
}

// header css end
